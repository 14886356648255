import axios from "axios";
import swal from "sweetalert";
import Settings from "../utils/settings";

export const BACKEND_BASE_URL = Settings["BACKEND_BASE_URL"];
// export const BACKEND_BASE_URL = "https://uat-api.perspectv.app/api";
// export const BACKEND_BASE_URL = "http://localhost:5800/api";

function setHeader() {
  let userToken = JSON.parse(localStorage.getItem("userDetails")).idToken;
  const Header = {
    Authorization: `Bearer ${userToken}`,
    "Content-Type": "application/json",
  };
  return Header;
}
function setFileHeader() {
  let userToken = JSON.parse(localStorage.getItem("userDetails")).idToken;
  const Header = {
    Authorization: `Bearer ${userToken}`,
    "Content-Type": "multipart/form-data",
  };
  return Header;
}

export const adminEmail = "perspectv@perspectv.app";
export const answersType = { 1: "Job Seeker", 2: "Job Poster", 3: "Both" };
export const answersStatus = { 1: "Approve", 0: "Denied", 2: "Pending" };
export const answersStatusBG = {
  1: "badge-success",
  0: "badge-primary",
  2: "badge-secondary",
};

export async function getData(path, data) {
  try {
    const Header = setHeader();
    const url = `${BACKEND_BASE_URL}${path}`;
    let response = await axios.get(url, {
      headers: Header,
      params: data,
    });
    return response;
  } catch {
    swal(
      "Oops",
      "Some Problem on fetching data.Please contact administartor.",
      "error"
    );
    return "";
  }
}

export async function getDataByPayload(path, data) {
  try {
    const Header = setHeader();
    const url = `${BACKEND_BASE_URL}${path}`;
    let response = await axios.post(url, data, {
      headers: Header,
    });
    return response;
  } catch {
    swal(
      "Oops",
      "Some Problem on fetching data.Please contact administartor.",
      "error"
    );
    return "";
  }
}
export async function fileUploadData(path, data) {
  try {
    const Header = setFileHeader();
    const url = `${BACKEND_BASE_URL}${path}`;
    let response = await axios.post(url, data, {
      headers: Header,
    });
    return response;
  } catch {
    swal(
      "Oops",
      "Some Problem on fetching data.Please contact administartor.",
      "error"
    );
    return "";
  }
}

export async function saveData(path, data) {
  try {
    const Header = setHeader();
    const url = `${BACKEND_BASE_URL}${path}`;
    let response = await axios.post(url, data, { headers: Header });
    return response;
  } catch {
    swal(
      "Oops",
      "Some Problem on saving data.Please contact administartor.",
      "error"
    );
    return "";
  }
}

export async function updateData(path, data) {
  try {
    const Header = setHeader();
    const url = `${BACKEND_BASE_URL}${path}`;
    let response = await axios.put(url, data, { headers: Header });
    return response;
  } catch {
    swal(
      "Oops",
      "Some Problem on fetching data.Please contact administartor.",
      "error"
    );
    return "";
  }
}

export async function deleteData(path) {
  try {
    const Header = setHeader();
    const url = `${BACKEND_BASE_URL}${path}`;
    let response = await axios.delete(url, {
      headers: Header,
    });
    return response;
  } catch {
    swal(
      "Oops",
      "Some Problem on deleteing data.Please contact administartor.",
      "error"
    );
    return "";
  }
}

export async function getMatchMakingWeight(path) {
  try {
    const url = `${BACKEND_BASE_URL}${path}`;
    let response = await axios.get(url);
    return response;
  } catch {
    swal(
      "Oops",
      "Some Problem on deleteing data.Please contact administartor.",
      "error"
    );
    return "";
  }
}

export async function updateRecord(path, payload) {
  try {
    const url = `${BACKEND_BASE_URL}${path}`;
    let response = await axios.post(url, { payload });
    return response;
  } catch {
    swal(
      "Oops",
      "Some Problem on deleteing data.Please contact administartor.",
      "error"
    );
    return "";
  }
}
