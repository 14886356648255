import { getData, updateData, deleteData, getDataByPayload } from "./Common";

export async function getJobPosterLists(data) {
  const url = `/admin/jobposter`;
  let response = getData(url, data);
  return response;
}
export async function deletePoster(id) {
  const url = `/admin/jobposter/delete/${id}`;
  let response = deleteData(url);
  return response;
}
export async function getJobSeekerLists(data) {
  const url = `/admin/jobseeker`;
  let response = getData(url, data);
  return response;
}
export async function getCreditHistory(userId) {
  let payload = {
    userId,
  };
  const url = `/admin/credit-history`;
  let response = await getDataByPayload(url, payload);
  return response;
}
export async function getAssociatedData(id, usertype) {
  let payload = {
    usertype: usertype,
    userId: id,
  };
  const url = `/jobposter/fetchjobposter`;
  let response = await getDataByPayload(url, payload);
  return response;
}
export async function deleteSeeker(id) {
  const url = `/admin/jobseeker/delete/${id}`;
  let response = deleteData(url);
  return response;
}
export async function updateJobSeekerStatus(payload) {
  const url = `/admin/jobseeker/updatestatus`;
  let response = updateData(url, payload);
  return response;
}
export async function updateJobPosterStatus(payload) {
  const url = `/admin/jobposter/updatestatus`;
  let response = updateData(url, payload);
  return response;
}
