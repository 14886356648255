import {getData,saveData,updateData,deleteData} from "./Common";

export async function getPlanLists(data) {
    const url = `/plan/list`
    let response = getData(url,data)
    return response
}
export async function deletePlan(id) {
    const url = `/plan/delete/${id}`
    let response = deleteData(url)
    return response
}

export async function updatePlan(payload) {
    const url = `/plan/update/${payload.id}`
    let response = updateData(url,payload)
    return response
}
export async function savePlan(payload) {
    const url = `/plan/save`
    let response = saveData(url,payload)
    return response
}
export async function statusChangePlan(payload) {
    const url = `/plan/statuschange/${payload.id}`
    let response = updateData(url,payload)
    return response
}
