import React,{useState,useContext, useEffect} from 'react';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import VacanyTab from '../Jobick/Home/VacanyTab';
import CanvasChartTab from '../Jobick/Home/CanvasChartTab';


//Components
const NewCustomers1 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers1"), 1000)
);
const NewCustomers2 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers2"), 1000)
);
const NewCustomers3 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers3"), 1000)
);
const NewCustomers4 = loadable(() =>
	pMinDelay(import("../Jobick/Home/NewCustomers4"), 1000)
);
const ApexPieChart = loadable(() =>
	pMinDelay(import("../Jobick/Home/ApexPieChart"), 1000)
);


const DashboardDark = () => {	
	


	const { changeBackground } = useContext(ThemeContext);
	  useEffect(() => {
		changeBackground({ value: "dark", label: "Dark" });
	}, []);
	return(
		<>
			<div className="row">
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="row separate-row">
										<div className="col-sm-6">
											<div className="job-icon pb-4 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">342</h2>
														<span className="text-success ms-3">+0.5 %</span>
													</div>	
													<span className="fs-14 d-block mb-2">Total numbedddr of subscriptions bought</span>
												</div>	
												<div id="NewCustomers">
													<NewCustomers1 />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">984</h2>
													</div>	
													<span className="fs-14 d-block mb-2">Total number of profiles bought</span>
												</div>	
												<div id="NewCustomers1">
													<NewCustomers2 />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">1,567k</h2>
														<span className="text-danger ms-3">-2 % </span>
													</div>	
													<span className="fs-14 d-block mb-2">Total Income</span>
												</div>	
												<div id="NewCustomers2">
													<NewCustomers3 />
												</div>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="job-icon pt-4  d-flex justify-content-between">
												<div>
													<div className="d-flex align-items-center mb-1">
														<h2 className="mb-0">437</h2>
													</div>	
													<span className="fs-14 d-block mb-2">Unread Messages</span>
												</div>	
												<div id="NewCustomers3">
													<NewCustomers4 />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-12">
							<VacanyTab />
						</div>
						<div className="col-xl-12">
							<CanvasChartTab />
						</div>
					</div>
				</div>
				<div className="col-xl-6">
					<div className="row">
						<div className="col-xl-12">
							<div className="card">
								<div className="card-body">
									<div className="row mt-4 align-items-center">
										<h4 className="fs-20 mb-3">Job seekers</h4>
										<div className="col-xl-6 col-sm-6">
											<div className="progress default-progress">
												<div className="progress-bar bg-green progress-animated" style={{width: "90%", height:"13px"}} role="progressbar">
													<span className="sr-only">90% Complete</span>
												</div>
											</div>
											<div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
												<span className="fs-14 font-w500">Figma</span>
												<span className="fs-16"><span className="text-black pe-2"></span>90%</span>
											</div>
											<div className="progress default-progress">
												<div className="progress-bar bg-info progress-animated" style={{width: "68%", height:"13px"}} role="progressbar">
													<span className="sr-only">45% Complete</span>
												</div>
											</div>
											<div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
												<span className="fs-14 font-w500">Adobe XD</span>
												<span className="fs-16"><span className="text-black pe-2"></span>68%</span>
											</div>
											<div className="progress default-progress">
												<div className="progress-bar bg-blue progress-animated" style={{width: "85%", height:"13px"}} role="progressbar">
													<span className="sr-only">85% Complete</span>
												</div>
											</div>
											<div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
												<span className="fs-14 font-w500">Photoshop</span>
												<span className="fs-16"><span className="text-black pe-2"></span>85%</span>
											</div>
										</div>
										<div className="col-xl-6 col-sm-6">
											<div id="pieChart1">
												<ApexPieChart />
											</div>
										</div>
									</div>
									<div className="row mt-4 align-items-center">
										<h4 className="fs-20 mb-3">Job posters</h4>
										<div className="col-xl-6 col-sm-6">
											<div className="progress default-progress">
												<div className="progress-bar bg-green progress-animated" style={{width: "90%", height:"13px"}} role="progressbar">
													<span className="sr-only">90% Complete</span>
												</div>
											</div>
											<div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
												<span className="fs-14 font-w500">Figma</span>
												<span className="fs-16"><span className="text-black pe-2"></span>90%</span>
											</div>
											<div className="progress default-progress">
												<div className="progress-bar bg-info progress-animated" style={{width: "68%", height:"13px"}} role="progressbar">
													<span className="sr-only">45% Complete</span>
												</div>
											</div>
											<div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
												<span className="fs-14 font-w500">Adobe XD</span>
												<span className="fs-16"><span className="text-black pe-2"></span>68%</span>
											</div>
											<div className="progress default-progress">
												<div className="progress-bar bg-blue progress-animated" style={{width: "85%", height:"13px"}} role="progressbar">
													<span className="sr-only">85% Complete</span>
												</div>
											</div>
											<div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
												<span className="fs-14 font-w500">Photoshop</span>
												<span className="fs-16"><span className="text-black pe-2"></span>85%</span>
											</div>
										</div>
										<div className="col-xl-6 col-sm-6">
											<div id="pieChart1">
												<ApexPieChart />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>	
		</>
	)
}
export default DashboardDark;