import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAssociatedData } from '../../../services/JobController';

import user from './../../../images/user.jpg';

const UserProfile = () => {
	const sessionData = localStorage.getItem('myContact');
	const data = JSON.parse(sessionData);
	const [detail, setDetail] = useState();
	const getData = async () => {
		const response = await getAssociatedData(data._id,2);
		if (response.status == 200) {
			setDetail(response.data.result[0]);
		}
	}
	const featurePoint = (val) => {
		console.log("Detail",detail)
		if (detail) {
			let feature;
			switch (val) {
				case 'jobtitle':
					feature =  (detail.jobtitle) ? (detail.jobtitle[0].option) : ""
					break;
				case 'industry':
					feature =  (detail.industry) ? (detail.industry[0].option) : ""
					break;
				case 'education':
					feature =  (detail.education) ? (detail.education[0].option) : ""
					break;
				case 'location':
					// feature = (detail?.location) ? ` ${(detail?.location[0].city)}, ${(detail?.location[0].state)}` : ""
					feature = ""
					break;
				case 'role':
					feature =  (detail.role) ? (detail.role[0].option) : ""
					break;
				case 'comapnySize':
					feature = (detail.comapnySize) ? (detail.campanySize) : "0"
					break;
				case 'employmentType':
					feature = (detail.employmentType) ? (detail.employmentType): ""
					break;
					case 'employmentMode':
						feature = (detail.employmentMode) ? (detail.employmentMode): ""
						break;
			}
			return feature;
		} else {
			return "";
		}
	}
	const skill = (val) => {
		if (detail) {
			let skills;
			if (val === 'soft') {
				skills = detail.softSkill.map((val) => {
					return val.skill
				})
			}
			if (val === 'hard') {
				skills = detail.hardSkill.map((val) => {
					return val.skill
				})
			}

			return skills.join(', ')
		} else {
			return "";
		}
	}

	useEffect(() => {
		getData()
	}, [])

	return (
		<>
			<div className="d-flex align-items-center mb-4">
				<h4 className="fs-20 font-w600 mb-0 me-auto">User Profile</h4>
			</div>
			<div className="row">
				<div className="col-xl-12">
					<div className="card">
						<div className="card-header border-0 flex-wrap align-items-start">
							<div className="col-md-8">
								<div className="user d-sm-flex d-block pe-md-5 pe-0">
									<img src={(data.imagePath) ? data.imagePath : user} alt="" />
									<div className="ms-sm-3 ms-0 me-md-5 md-0">
										<h5 className="mb-1 font-w600"><Link to={"#"}>{(data.fullName) ? data.fullName : ''}</Link></h5>
										<div className="listline-wrapper mb-2">
											<span className="item"><i className="far fa-envelope"></i>{(data.email) ? data.email : ''}</span>
											{/* <span className="item"><i className="far fa-id-badge"></i>Manager</span> */}
											<span className="item"><i className="fas fa-map-marker-alt"></i>{(data.location) ? data.location : ''}</span>
											<span className="item"><i className="fas fa-mobile"></i>{(data.contactNumber) ? data.contactNumber : ''}</span>
										</div>
										<p>{(data.desc) ? data.desc : ''}</p>
										<p>{(data.companyDesc) ? data.companyDesc : ''}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="card-body pt-0">
							<h4 className="fs-20">Description</h4>
							<div className="row">
								<div className="col-xl-6 col-md-6">
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Name : </span><span className="font-w400">{(data.fullName) ? data.fullName : ''}</span></p>
									{data.companyName ?
										<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Company : </span><span className="font-w400">{data.companyName}</span></p>
										:
										// <p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Company size : </span><span className="font-w400">123</span></p> 
										''
									}

									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Job Title : </span><span className="font-w400">{featurePoint('jobtitle')}</span></p>
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Role : </span><span className="font-w400">{featurePoint('role')}</span></p>
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Industry :</span> <span className="font-w400">{featurePoint('industry')}</span></p>
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Education : </span><span className="font-w400">{featurePoint('education')}</span></p>
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Location: </span><span className="font-w400">{featurePoint('location')}</span></p>
								</div>
								<div className="col-xl-6 col-md-6">
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Email ID :</span> <span className="font-w400">{(data.email) ? data.email : ''}</span></p>
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Phone number : </span><span className="font-w400">{(data.contactNumber) ? data.contactNumber : ''}</span></p>
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Company Size :</span> <span className="font-w400">{ featurePoint('comapnySize')}</span></p>
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Employment Type : </span><span className="font-w400">{ featurePoint('employmentType')}</span></p>
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Employment Mode  :</span> <span className="font-w400">{ featurePoint('employmentMode')}</span></p>
									<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">Soft Skill: </span><span className="font-w400">{skill('soft')}</span></p>

									{
										data.document ?
											<p className="font-w600 mb-2 d-flex"><span className="custom-label-2">CV: </span><span className="font-w400"><a href="#">React_Resume</a></span></p>
											: ""
									}
								</div>
							</div>
						</div>
						<div className="card-footer d-flex flex-wrap justify-content-between">
							{
								data.videoPath && data.videoResume ?
									<video width="350" height="200" controls >
										<source src={`${data.videoPath}`} type="video/mp4" />
									</video>
									: ""
							}

						</div>
						<div className="card-footer d-flex flex-wrap justify-content-between">
							{
								data.document ?
									<div>
										<Link to={"#"} className="btn btn-primary btn-md me-2  mb-2"><i className="fas fa-download me-2"></i>Download Resume</Link>
									</div>
									: ""
							}

						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default UserProfile;