import { getData, saveData, updateData, deleteData, getDataByPayload } from "../services/Common";

export async function getRoleQuestions(data) {
    const url = `/admin/onboarding/getjobrole`
    let response = getData(url, data)
    return response

}
export async function updateRoleQuestion(data, sid) {
    console.log("update role question got hit", data, sid);
    const url = `/admin/onboarding/updatejobrole/${sid}`
    let response = updateData(url, data)
    return response
}

export async function getRoles(data) {

    const url = `/admin/onboarding/getjobroleoptions`
    let response = getData(url, data);
    return response
}

export async function getRolesPayload(data) {
    const url = `/admin/onboarding/getjobroleoptions`
    let response = getDataByPayload(url, data);
    return response

}

export async function getRoleQuestionsPayload(data) {
    const url = `/admin/onboarding/getjobrole`
    let response = getDataByPayload(url, data);
    return response

}


export async function getEditRole(id) {
    const url = `/admin/jobrole/editjobroleoptions/${id}`
    let response = getData(url);
    return response
}

export async function addRole(data) {
    const url = `/admin/onboarding/addjobroles`
    let response = saveData(url, data);
    return response
}

export async function updateRole(data, sid) {
    const url = `/admin/onboarding/updatejobroleoptions/${sid}`
    let response = updateData(url, data);
    return response
}

export async function ruleApprove(sid) {
    const url = `/admin/onboarding/approvejobroleoptions/${sid}`
    let response = updateData(url, "")
    return response
}

export async function ruleDisapprove(sid) {
    const url = `/admin/onboarding/denyjobroleoptions/${sid}`
    let response = updateData(url, "")
    return response
}


export async function deleteRole(id) {
    const url = `/admin/onboarding/deletejobroleoptions/${id}`
    let response = deleteData(url)
    return response
}


