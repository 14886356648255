import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { nanoid } from "nanoid";
import swal from "sweetalert";
import {
  deleteSeeker as deleteSeekerApi,
  getJobSeekerLists,
  updateJobSeekerStatus,
} from "../../../services/JobController";
import Alert from "sweetalert2";
// import moment from "moment";
import JobsTable from "../table/jobs-list";

const TableContent = [];

const JobLists = () => {
  //Modal box

  const [contacts, setContacts] = useState(TableContent);

  // delete data
  const handleDeleteClick = (contactId) => {
    const alert_options = {
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }
    Alert.fire(alert_options).then(async (result) => {
      if (result.isConfirmed) {
        deleteSeeker(contactId)
      }
    });
  };

  function deleteSeeker(contactId){
    deleteSeekerApi(contactId).then((_resp) => {
      getJobSeekers();
      Alert.fire("Deleted!", "Your data has been deleted.", "success");
    }).then((_resp) => {

    }).finally(() => {

    })
  }

  // Edit function editable page loop
  const [editContactId, setEditContactId] = useState(null);
  const handleStatus = async (email, status) => {
    let payload = {
      email: email,
      status: status,
    };
    let response = await updateJobSeekerStatus(payload);
    if (response.status == 200) {
      swal("Good job!", response.data.message, "success");
      getJobSeekers();
    } else {
      swal("Oops", "Some error...", "error");
    }
  };

  const handleViewProfile = async (contact) => {
    localStorage.setItem("myContact", JSON.stringify(contact));
  };
  // edit  data
  const [editFormData, setEditFormData] = useState({
    name: "",
    email: "",
    industry: "",
    role: "",
    cdate: "",
    status: "",
  });

  useEffect(() => {
    getJobSeekers();
  }, []);

  function getJobSeekers() {
    const payload = ""
    getJobSeekerLists(payload).then((_resp) => {
      const data = _resp.data.result
      setContacts(data.reverse());
    }).catch((_resp) => {
      console.error(_resp)
    }).finally(() => {

    })
  }

  function onJobsTableResp(_cmd, _value){
    if(_cmd === 'view'){
      const rec = contacts.find(x => x['_id'] === _value['_id'])
      handleViewProfile(rec)
    }else if(_cmd === 'remove'){
      handleDeleteClick(_value['_id'])
    }
  }

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600 me-auto">Job Seekers</h4>
        <div>
          <i onClick={getJobSeekers} className="mdi mdi-refresh text-info" type={'button'}>Refresh</i>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <JobsTable _data={contacts} _onResp={onJobsTableResp}/>
        </div>
      </div>
    </>
  );
};
export default JobLists;
