import React,{useEffect, useState} from 'react';
// import {Link} from 'react-router-dom';
// import {Dropdown} from 'react-bootstrap';
// import {nanoid} from 'nanoid';
import swal from "sweetalert";
import { deletePoster as deletePosterApi, 
	getJobPosterLists as getJobPosterListsApi, 
	updateJobPosterStatus as updateJobPosterStatusApi } from '../../../services/JobController';
import Alert from "sweetalert2";
// import moment from 'moment';
import PosterListTable from '../table/poster-list-table';

const TableContent = [];

const PosterLists = () => {
const [pageMounted, setPageMounted] = useState(false)
const [contacts, setContacts] = useState(TableContent);
const [editModal, setEditModal] = useState(false);	
const [editContactId, setEditContactId] = useState(null);


useEffect(() => {
	if(!pageMounted){
		setPageMounted(true)
		getJobPoster();
	}
}, [pageMounted]);

function getJobPoster() {
	const payload = ""
	getJobPosterListsApi(payload).then((_resp) => {
		if(_resp.data.message === 'success'){
			const records = _resp.data.result
			for(const x in records){
				let rec = records[x]
				if([undefined, null].includes(rec[''])){
					rec['companyName'] = rec['companyName'] !== undefined && rec['companyName'] !== null ? rec['companyName'] : 'Unknown Name'
					// console.warn(rec)
				}
			}
			setContacts(records.reverse())
		}else{

		}
	}).catch((_resp) => {
		console.log(_resp)
	}).finally(() => {

	})
}
	
const handleDeleteClick = (contactId) => {
    Alert.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async(result) => {
			if (result.isConfirmed) {
				deletePoster(contactId);
			}
		})
        
}

function deletePoster(_doc_id){
	deletePosterApi(_doc_id).then((_resp) => {
		getJobPoster();
	}).catch((_resp) => {

	}).finally(() => {

	})
}
	
const [addFormData, setAddFormData ] = useState({
        name:'',
        email:'',
        industry:'',
        role:'',
        cdate:'',
        status:'',
}); 
    
const handleViewProfile = (contact) => {
	localStorage.setItem('myContact', JSON.stringify(contact));
}

const handleStatus = async (email,status) => { 
let payload = {
	email: email,
	status: status
}
let response = await updateJobPosterStatus(payload);
if (response.status == 200) {
	swal('Good job!', response.data.message, "success");
	getJobPoster();
} else { 
	swal('Oops', "Some error...", "error");
}
}

function onPosterListTableResp(_cmd, _value){
	if(_cmd === 'view'){
		handleViewProfile(_value)
		// console.log(_value)
	}else if(_cmd === 'remove'){
		handleDeleteClick(_value['_id'])
	}
}

return(
<>
	<div className="d-flex align-items-center mb-4 flex-wrap">
		<h4 className="fs-20 font-w600  me-auto">Job Posters</h4>
		<i onClick={getJobPoster} type={'button'} className='mdi mdi-refresh text-info fs-4'>Refresh</i>
		{/* <div>
			<Dropdown className="btn-group mb-1">
			<Dropdown.Toggle
				type="button"
				className="btn btn-primary me-3 btn-sm dropdown-toggle"
				data-toggle="dropdown"
			>
				Sort by<span className="caret m-l-5"></span>
			</Dropdown.Toggle>
			<Dropdown.Menu className="dropdown-menu">
				<Dropdown.Item
				className="dropdown-item"
				to="#"
				>
				Account creation date
				</Dropdown.Item>
				<Dropdown.Item
				className="dropdown-item"
				to="#"
				>
				Alphabetical order
				</Dropdown.Item>
				<Dropdown.Item
				className="dropdown-item"
				to="#"
				>
				Experience
				</Dropdown.Item>
				
			</Dropdown.Menu>
			</Dropdown>

				<Dropdown className="btn-group mb-1">
			<Dropdown.Toggle
				type="button"
				className="btn btn-primary me-3 btn-sm dropdown-toggle"
				data-toggle="dropdown"
			>
				Filter by<span className="caret m-l-5"></span>
			</Dropdown.Toggle>
			<Dropdown.Menu className="dropdown-menu">
				<Dropdown.Item
				className="dropdown-item"
				to="#"
				>
				By Role
				</Dropdown.Item>
				<Dropdown.Item
				className="dropdown-item"
				to="#"
				>
				Industry
				</Dropdown.Item>
				<Dropdown.Item
				className="dropdown-item"
				to="#"
				>
				Premium Users
				</Dropdown.Item>
				
			</Dropdown.Menu>
			</Dropdown>
		</div> */}
	</div>
	<div className="row">
		<div className="col-12">
			<PosterListTable _data={contacts} _onResp={onPosterListTableResp}/>
		</div>
	</div>
</>
)	
}
export default PosterLists;