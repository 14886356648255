import React, { useEffect, useState } from "react";
import {
  getCategories,
  saveAssessmentData,
  getCategoryOptionType,
} from "../../../services/SelfAssessmentServices";
import swal from "sweetalert";
export default function AddSelfAssessment(props) {
  const [questionShow, setQuestionShow] = useState("");
  const [categoryFor, setCategoryFor] = useState(1);
  const [categories, setcategories] = useState([]);
  const [optionsValue, setOptionValue] = useState(["a", "b"]);
  const [assessmentPayload, setAssessmentPayload] = useState({});
  const [assessmentoptions, setAssessmentoptions] = useState([]);

  const [resultOption, setResultOption] = useState([
    { id: 1, name: "Extrovert", value: "E" },
    { id: 2, name: "Introvert", value: "I" },
  ]);
  const [range, setRange] = useState([
    { id: 0, val: 50 },
    { id: 1, val: 50 },
    { id: 2, val: 50 },
    { id: 3, val: 50 },
  ]);
  const selectOption = (e) => {
    setOptionValue(["a", "b"]);
    setAssessmentoptions([]);
    setQuestionShow(e.target.value);
    if (e.target.value != "") {
      getCatType(e.target.value);
    }
  };

  const getCatType = async (id) => {
    const response = await getCategoryOptionType(id);
    if (response.status == 200) {
      let arr = response.data.result.type;
      let n = arr.map((item) => {
        return { id: item.id, name: item.options, value: item.value };
      });
      setResultOption(n);
    } else {
      setResultOption([
        { id: 1, name: "Assertive", value: "A" },
        { id: 2, name: "Turbulent", value: "T" },
      ]);
    }
  };

  const handleCreateOption = (e) => {
    e.preventDefault();
    if (optionsValue.length < 4) {
      setOptionValue([...optionsValue, "asd"]);
    }
  };

  const handleRange = (id, val) => {
    if (range.length > 0) {
      let data = range.filter((val) => {
        return val.id != id;
      });

      setRange([...data, { id: id, val: val }]);
    } else {
      setRange([{ id: id, val: val }]);
    }
    setOption("p", val, id);
  };
  const myVal = (idx) => {
    let rangeVal = range.filter((val) => {
      return val.id == idx;
    });
    return rangeVal[0].val;
  };
  const setOption = (type, value, id) => {
    if (assessmentoptions.length > 0 && id < assessmentoptions.length) {
      let data1 = assessmentoptions.map((val, key) => {
        if (key == id) {
          switch (type) {
            case "o":
              val.options = value;
              break;
            case "r":
              val.result = value;
              break;
            case "p":
              val.percentage = value;
              break;
          }
        }
        return val;
      });
      setAssessmentoptions(data1);
    } else {
      switch (type) {
        case "o":
          setAssessmentoptions([
            ...assessmentoptions,
            { options: value, result: resultOption[0].name, percentage: 50 },
          ]);
          break;
        case "r":
          setAssessmentoptions([
            ...assessmentoptions,
            { options: "", result: value, percentage: 50 },
          ]);
          break;
        case "p":
          setAssessmentoptions([
            ...assessmentoptions,
            { options: "", result: resultOption[0].name, percentage: value },
          ]);
          break;
      }
    }
  };

  function cancelAssessment() {
    localStorage.setItem("catId", questionShow);
    props.history.push("/self-Assessment");
  }
  const checkEmptyAnswer = (data) => {
    if (data.length >= 2) {
      let emptyData = data.filter((val) => {
        return val.options.trim().length == 0;
      });
      if (emptyData.length >= 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  const saveAssessment = async (e) => {
    let data = {
      ...assessmentPayload,
      category: questionShow,
      mode: categoryFor,
      answers: assessmentoptions,
    };
    let SubmitStatus =
      data.category != "" &&
      data.answers.length >= 2 &&
      checkEmptyAnswer(data.answers)
        ? true
        : false;
    if (SubmitStatus) {
      const response = await saveAssessmentData(data);
      if (response.status == 200) {
        swal("Good job!", response.data.message, "success");
        localStorage.setItem("catId", questionShow);
        localStorage.setItem("mode", categoryFor);
        props.history.push("/self-Assessment");
      } else {
        swal("Oops", "Some error...", "error");
      }
    } else {
      swal("Oops", "Please fill the details...", "error");
    }
  };
  async function getCategoryData() {
    const response = await getCategories();
    if (response.status == 200) {
      setcategories(response.data.category);
      console.log(response.data.category);
    }
  }
  useEffect(() => {
    getCategoryData();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Choose Detail</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="mb-3 row">
                  <label
                    className="col-sm-3 col-form-label mt-2"
                    style={{ fontWeight: "bold" }}
                  >
                    Category Name:
                  </label>
                  <div className="col-sm-9">
                    <div className="form-group mb-3 position-relative">
                      <i
                        class="fa fa-angle-down position-absolute start-3"
                        aria-hidden="true"
                        style={{ fontSize: "25px", right: "25px", top: "20px" }}
                      ></i>
                      <select
                        defaultValue={"option"}
                        onChange={selectOption}
                        className="form-control form-control-lg "
                      >
                        <option value="">Select Category</option>
                        {categories
                          ? categories.map((val, idx) => {
                              return (
                                <option
                                  value={val._id}
                                  id={`${val.category}`}
                                  key={idx}
                                >
                                  {val.category}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label
                    className="col-sm-3 col-form-label mt-2"
                    style={{ fontWeight: "bold" }}
                  >
                    Category For:
                  </label>
                  <div className="col-sm-9">
                    <div className="form-group mb-3 position-relative">
                      <i
                        class="fa fa-angle-down position-absolute start-3"
                        aria-hidden="true"
                        style={{ fontSize: "25px", right: "25px", top: "20px" }}
                      ></i>
                      <select
                        defaultValue={categoryFor}
                        onChange={() => {
                          setCategoryFor(2);
                        }}
                        className="form-control form-control-lg "
                      >
                        <option value="2">Job Poster</option>
                        <option value="1">Job Seeker</option>
                      </select>
                    </div>
                  </div>
                </div>
                {questionShow ? (
                  <div className="row">
                    <div className="card">
                      <div className="card-header">
                        <h6 className="card-title ">Create Question</h6>
                      </div>
                      <div className="card-body">
                        <div className="basic-form">
                          <form onSubmit={(e) => e.preventDefault()}>
                            <div className="mb-3 row">
                              <label
                                className="col-sm-3 col-form-label"
                                style={{ fontWeight: "bold" }}
                              >
                                Question:
                              </label>
                              <div className="col-sm-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  onChange={(e) => {
                                    setAssessmentPayload({
                                      question: e.target.value,
                                    });
                                  }}
                                  placeholder="Enter your Question Here..."
                                />
                              </div>
                              <div className="col-sm-3 mt-3">
                                <a href="#" onClick={handleCreateOption}>
                                  <i className="fas fa-plus me-2"></i>Add New
                                  Options
                                </a>
                              </div>
                            </div>
                            <div className="ms-5">
                              {optionsValue.map((val, idx) => {
                                return (
                                  <div className="mb-3 row" key={idx}>
                                    <label className="col-sm-3 col-form-label">
                                      Option
                                    </label>
                                    <div className="col-sm-3">
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          setOption("o", e.target.value, idx);
                                        }}
                                        className="form-control"
                                        placeholder="Enter your Option"
                                      />
                                    </div>
                                    <div className="col-sm-3 position-relative">
                                      <i
                                        class="fa fa-angle-down position-absolute start-3"
                                        aria-hidden="true"
                                        style={{
                                          fontSize: "25px",
                                          right: "25px",
                                          top: "15px",
                                        }}
                                      ></i>

                                      <select
                                        defaultValue={"option"}
                                        onChange={(e) => {
                                          setOption("r", e.target.value, idx);
                                        }}
                                        className="form-control form-control-lg"
                                      >
                                        {resultOption.map((val, idx) => {
                                          return (
                                            <option value={val.name} key={idx}>
                                              {val.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="col-sm-2">
                                      <div className="form-check">
                                        <div className="mt-4">
                                          <input
                                            type="range"
                                            min={0}
                                            max={100}
                                            onChange={(event) =>
                                              handleRange(
                                                idx,
                                                event.target.value
                                              )
                                            }
                                          />
                                          <span
                                            className="bold"
                                            style={{ fontWeight: "bold" }}
                                          >
                                            {myVal(idx)}%
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-12" style={{ textAlign: "right" }}>
                  <button
                    type="submit"
                    className="btn btn-primary mb-2"
                    onClick={saveAssessment}
                  >
                    Submit
                  </button>
                  <button
                    type="submit"
                    onClick={cancelAssessment}
                    className="btn  mb-2"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
