import {
  getData,
  getMatchMakingWeight,
  saveData,
  updateRecord,
} from "./Common";

export async function getPoints(data) {
  const url = `/pointsystem/points`;
  let response = getData(url, data);
  return response;
}

export async function updatePlan(payload) {
  const url = `/pointsystem/update`;
  let response = saveData(url, payload);
  return response;
}

export async function fetchMatchMakingWeight() {
  const url = `/matchmakingWeight/fetch`;
  let response = await getMatchMakingWeight(url);
  return response;
}

export async function updateMatchMakingWeight(payload) {
  const url = `/matchmakingWeight/updateAll`;
  let response = await updateRecord(url, payload);
  return response;
}
