import {
  getData,
  saveData,
  updateData,
  deleteData,
  getDataByPayload,
} from "../services/Common";


export async function getCategories() {
  const url = `/admin/getallselfassessmentcategory`;
  let response = getData(url);
  return response;
}

export async function saveAssessmentData(data) {
  const url = `/admin/self/addselfassessmentquestions`;
  let response = saveData(url, data);
  return response;
}

export async function getEditData(sid) {
  const url = `/admin/self/addselfassessmentquestions/${sid}`;
  let response = getData(url);
  return response;
}

export async function updateAssessmentData(data, sid) {
  const url = `/admin/self/updateselfassessmentquestions/${sid}`;
  let response = updateData(url, data);
  return response;
}

export async function getAssessmentList(payload) {
  const url = `/admin/self/getallselfassesmentcategory`;
  let response = getDataByPayload(url, payload);
  return response;
}

export async function deleteAssessmentList(catId) {
  const url = `/admin/self/deleteselfassessmentquestion/${catId}`;
  let response = deleteData(url);
  return response;
}

export async function getCategoryOptionType(catId) {
  const url = `/admin/type/getcategorytypes/${catId}`;
  let response = getData(url);
  return response;
}
