import React, { useEffect, useState } from "react";
import {
  fetchMatchMakingWeight,
  updateMatchMakingWeight,
  // getPoints,
  // updatePlan,
} from "../../../services/PointSystem";
import swal from "sweetalert";

const PointSystem = () => {
  // const [seekerData, setSeekerData] = useState();
  // const [posterData, setPosterData] = useState();
  // const [editSeekerData, setEditSeekerData] = useState({});
  // const [editPosterData, setEditPosterData] = useState({});

  // //Function for set Poster Data
  // const handlePosterData = (e) => {
  //   const fieldName = e.target.getAttribute("name");
  //   const fieldValue = e.target.value.trim();
  //   if (Number(fieldValue) < 0) {
  //     return swal("Error", "Negative Number is not allowed", "error");
  //   }

  //   const newEditPosterData = { ...editPosterData };
  //   newEditPosterData[fieldName] = fieldValue;
  //   setEditPosterData(newEditPosterData);
  // };
  // //Function for set Seeker Data
  // const handleSeekerData = (e) => {
  //   const fieldName = e.target.getAttribute("name");
  //   const fieldValue =
  //     e.target.value.trim() != "" ? e.target.value.trim() : "1";
  //   const newSeekerData = { ...editSeekerData };
  //   newSeekerData[fieldName] = fieldValue;
  //   setEditSeekerData(newSeekerData);
  // };
  // const saveData = async () => {
  //   console.log(editSeekerData, editPosterData);
  //   console.log(
  //     Object.values(editPosterData).every((value) => value.length > 0)
  //   );
  //   console.log(
  //     Object.values(editSeekerData).every((value) => value.length > 0)
  //   );
  //   if (
  //     (Object.values(editSeekerData)?.length > 0 &&
  //       Object.values(editSeekerData).every((value) => value.length > 0)) ||
  //     (Object.values(editPosterData)?.length > 0 &&
  //       Object.values(editPosterData).every((value) => value.length > 0))
  //   ) {
  //     const dataObject = {
  //       jobSeeker: editSeekerData,
  //       jobPoster: editPosterData,
  //     };
  //     console.log("objectData", dataObject);
  //     const data = await updatePlan(dataObject);
  //     if (data.status === 200) {
  //       swal("Good job!", "Successfully Updated", "success");
  //       getData();
  //     }
  //   } else {
  //     swal("Oops", "Please Fill The Required Fields.", "error");
  //   }
  // };

  // const getData = async () => {
  //   let payload = "";
  //   const response = await getPoints(payload);
  //   if (response.status == 200) {
  //     if (response.data.result[0].userType === 2) {
  //       setPosterData(response.data.result[0]);
  //     } else {
  //       setSeekerData(response.data.result[0]);
  //     }
  //     if (response.data.result[1].userType === 2) {
  //       setPosterData(response.data.result[1]);
  //     } else {
  //       setSeekerData(response.data.result[1]);
  //     }
  //   }
  // };

  const [matchMakingWeight, setMatchMakingWeight] = useState([]);
  const [updateValue, setUpdateValue] = useState({});

  const handleData = (e) => {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value.trim();
    setUpdateValue((prevValue) => ({
      ...prevValue,
      [fieldName]: Number(fieldValue),
    }));
  };

  const saveData = async () => {
    const finalValue = matchMakingWeight.filter(
      (elem) => !Object.keys(updateValue).includes(elem.Name)
    );

    const updateArray = [];

    const entries = Object.entries(updateValue);

    for (let i = 0; i < entries.length; i++) {
      updateArray.push({
        Name: entries[i][0],
        Value: entries[i][1],
      });
    }

    const sumOfTheValue = +[...finalValue, ...updateArray]
      .map((elem) => elem.Value)
      .reduce((a, b) => a + b, 0)
        .toFixed(2);

    if (sumOfTheValue > 1) {
      return swal(
        "Error",
        "The sum of the values should not exceed 1.00",
        "error"
      );
    }

    const data = await updateMatchMakingWeight(updateArray);

    if (data.status === 200) {
      swal("Good job!", "Successfully Updated", "success");
      setUpdateValue({});
      fetchData();
    } else {
      swal("Oops", "Something went wrong.", "error");
    }
  };

  const fetchData = async () => {
    const data = await fetchMatchMakingWeight();
    setMatchMakingWeight(data?.data?.matchMakingWeight ?? []);
  };

  useEffect(() => {
    // getData();
    fetchData();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="text-end mt-4 mb-3">
          <button className="btn btn-primary me-3 btn" onClick={saveData}>
            SAVE
          </button>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-4 ms-3 mt-3">
              <h3 className="card-title">Point Value</h3>
            </div>
            {/* <div className="col-sm-4 mt-3">
                            <h3 className="card-title">Job Seeker</h3>
                        </div> */}
          </div>
          <div className="card-body">
            <div className="basic-form">
              <>
                {matchMakingWeight.length > 0 ? (
                  matchMakingWeight.map((val) => (
                    <div className="mb-3 row" key={val._id}>
                      <label
                        className="col-sm-3 col-form-label mt-3"
                        style={{ fontWeight: "bold" }}
                      >
                        {`${val.Name.charAt(
                          0
                        ).toUpperCase()}${val.Name.substring(
                          1,
                          val.Name.length
                        ).replace("Weight", "")}`}
                        :
                      </label>
                      <div className="col-sm-4">
                        <input
                          defaultValue={val.Value}
                          type="number"
                          name={val.Name}
                          min={0}
                          pattern="[0-9]*"
                          onChange={handleData}
                          className="form-control"
                          placeholder="Enter Job Poster Points"
                          style={{ border: "2px solid black" }}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Loading data</p>
                )}
              </>

              {/* {posterData != undefined && seekerData != undefined ? (
                <>
                  {Object.keys(posterData).map((val) => {
                    return (
                      <>
                        {val != "_id" &&
                        val != "userType" &&
                        val != "createdAt" &&
                        val != "updatedAt" &&
                        val != "__v" ? (
                          <div className="mb-3 row">
                            <label
                              className="col-sm-3 col-form-label mt-3"
                              style={{ fontWeight: "bold" }}
                            >
                              {val.charAt(0).toUpperCase()}
                              {val.substring(1, val.length)}:
                            </label>
                            <div className="col-sm-4">
                              <input
                                defaultValue={posterData[val]}
                                type="number"
                                name={val}
                                min={0}
                                pattern="[0-9]*"
                                onChange={handlePosterData}
                                className="form-control"
                                placeholder="Enter Job Poster Points"
                                style={{ border: "2px solid black" }}
                              />
                            </div>
                            {/* <div className="col-sm-4">
                                                                        <input
                                                                            defaultValue={seekerData[val]}
                                                                            type="text"
                                                                            name={val}
                                                                            onChange={handleSeekerData}

                                                                            className="form-control"
                                                                            placeholder="Enter Job Seeker Points"

                                                                        />
                                                                    </div> */}
              {/* </div>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </>
              ) : (
                <p>Loading data</p>
              )}  */}

              {/*  */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointSystem;
