import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import {
  changePassword,
  getAdminInfo,
  uploadProfile,
} from "../../../services/AuthService";
import { adminEmail } from "../../../services/Common";

export default function EditProfile() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profile, setProfile] = useState();
  const [file, setFile] = useState(undefined);
  const [fullName, setFullName] = useState();
  const handleChangePassword = async () => {

    if (confirmPassword || newPassword || oldPassword) {
      if (confirmPassword !== newPassword) {
        swal("Oops", "New password must be equal to confirm password", "error");
        return;
      } else {
        const payload = {
          email: adminEmail,
          prevPass: oldPassword,
          currentPass: newPassword,
        };
        const result = await changePassword(payload);
        console.log("result: ", result);
        if (result.status === 200) {
          swal("Success", "Password changed successfully", "success");
        } else {
          console.log(result);
          swal("Oops", "Some error occurred", "error");
        }
      }
    } else {
      swal("Oops", "Please filla all the fields", "error");

    }

  };
  const getAdmininfoData = async () => {
    const payload = {
      email: adminEmail,
    };
    const data = await getAdminInfo(payload);
    if (data.status === 200) {
      setProfile(data.data.user);
      setFullName(data.data.user.fullName);
    } else {
      swal("Oops", "Some error to fetch error", "error");
    }
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append("profilePic", file);
      formData.append("userId", profile._id);
      formData.append("fullName", fullName);
      const data = await uploadProfile(formData);
      if (data.status === 200) {
        getAdmininfoData();
        swal(
          "Oops",
          "Profile updated successfully,Reload for changes",
          "success"
        );
      } else {
        swal("Oops", "Fill all details", "error");
      }
    } else {
      const formData = new FormData();
      // formData.append("profilePic", file);
      formData.append("userId", profile._id);
      formData.append("fullName", fullName);
      const data = await uploadProfile(formData);
      if (data.status === 200) {
        getAdmininfoData();
        swal(
          "Oops",
          "Profile updated successfully,Reload for changes",
          "success"
        );
      }
    }
  };
  useEffect(() => {
    getAdmininfoData();
  }, []);
  return (
    <div>
      <div className="row card">
        <div className="col-sm-3 pt-2">
          <label
            className="col-sm-12 col-form-label mt-3"
            style={{ fontWeight: "bold" }}
          >
            <h4>Change Your Password</h4>
          </label>
        </div>
        <div className="px-4 ms-4">
          <div className="mb-1 row"></div>
          <div className="row">
            <div className="col-sm-3 pt-4">
              <label>Current Password</label>
            </div>
            <div className="col-sm-8 pt-2">
              <input
                type="password"
                className="form-control"
                placeholder="Old Password..."
                required="true"
                onChange={(event) => {
                  setOldPassword(event.target.value);
                }}
                style={{ border: "1px solid black" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 pt-4">
              <label>New Password</label>
            </div>
            <div className="col-sm-8 pt-2">
              <input
                type="password"
                className="form-control"
                placeholder="New Password..."
                required="true"
                onChange={(event) => {
                  setNewPassword(event.target.value);
                }}
                style={{ border: "1px solid black" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3 pt-4">
              <label>Confirm New Password</label>
            </div>
            <div className="col-sm-8 pt-2">
              <input
                type="password"
                className="form-control"
                placeholder="Confirm New Password..."
                required="true"
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                }}
                style={{ border: "1px solid black" }}
              />
            </div>
          </div>
        </div>

        <div className="text-end mt-4 mb-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleChangePassword}
          >
            Change Password
          </button>
        </div>
      </div>

      <div className="row card mt-5">
        <div className="col-sm-3 pt-2">
          <label
            className="col-sm-12 col-form-label mt-3"
            style={{ fontWeight: "bold" }}
          >
            <h4>Change Your Profile</h4>
          </label>
        </div>
        <div className="px-4 ms-4">
          <div className="mb-1 row"></div>
          <div className="row">
            <div className="col-sm-3 pt-4">
              <label>Name</label>
            </div>
            <div className="col-sm-8 pt-2">
              <input
                defaultValue={fullName ? fullName : ""}
                type="text"
                className="form-control"
                placeholder="Your Name"
                onChange={(event) => {
                  setFullName(event.target.value);
                }}
                style={{ border: "1px solid black" }}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-3 pt-4">
              <label>New Profile Picture</label>
            </div>
            <div className="col-sm-8 pt-4">
              <input
                type="file"
                className="form-file-input"
                placeholder="New Profile..."
                accept="image/png, image/gif, image/jpeg"
                onChange={(event) => {
                  setFile(event.target.files[0]);
                }}
              />
            </div>
          </div>
        </div>

        <div className="text-end mt-4 mb-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleUpload}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}
