import React, { useEffect, useState } from "react";
import {
  getCategories,
  getEditPersonalBuilderData,
  updatePersonalBuilderData,
} from "../../../services/PersonalBuilderSystem";
import swal from "sweetalert";
export default function AddSelfAssessment(props) {
  const [categories, setcategories] = useState([]);

  //Add data
  const [addFormData, setAddFormData] = useState({
    ptb1: "",
    ptb2: "",
    whb1: "",
    whb2: "",
    result: "",
    id: "",
  });

  // Add contact function
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  const saveAssessment = async (e) => {
    e.preventDefault();
    var error = false;
    var errorMsg = "";
    if (addFormData.ptb1 === "") {
      error = true;
      errorMsg = "Please fill ptb1";
    } else if (addFormData.ptb2 === "") {
      error = true;
      errorMsg = "Please fill ptb2";
    } else if (addFormData.whb1 === "") {
      error = true;
      errorMsg = "Please fill whb1";
    } else if (addFormData.whb2 === "") {
      error = true;
      errorMsg = "Please fill whb2";
    } else if (addFormData.result === "") {
      error = true;
      errorMsg = "Please fill result";
    }
    if (!error) {
      const newResult = {
        ptb1: addFormData.ptb1,
        ptb2: addFormData.ptb2,
        whb1: addFormData.whb1,
        whb2: addFormData.whb2,
        result: addFormData.result,
      };
      //console.log(addFormData);
      let result = await updatePersonalBuilderData(newResult, addFormData._id);
      if (result.status === 200) {
        swal("Good job!", "Successfully Updated", "success");
        props.history.push("/list-personal-Builder");
      } else {
        swal("Oops", "Something went wrong", "error");
      }
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  const cancelAssessment = async (e) => {
    props.history.push("/list-personal-Builder");
  };

  async function getCategoryData(PID) {
    const responseCategories = await getCategories();
    const responseEditPersonalBuilder = await getEditPersonalBuilderData(PID);
    if (responseCategories.status == 200) {
      setcategories(responseCategories.data.category);
    }
    if (responseEditPersonalBuilder.status == 200) {
      setAddFormData(responseEditPersonalBuilder.data.result);
    }
  }
  useEffect(() => {
    let PID = localStorage.getItem("pid");

    getCategoryData(PID);
    setAddFormData({ id: PID });
    //console.log(addFormData);
  }, []);

  return (
    <div>
      <div className="row">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Edit Perosnal Details </h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="mb-3 row">
                  <label
                    className="col-sm-3 col-form-label mt-3"
                    style={{ fontWeight: "bold" }}
                  >
                    Category Name:
                  </label>
                  <div className="col-sm-9">
                    <div className="form-group mb-3 position-relative">
                      <textarea
                        class="form-control"
                        name="result"
                        id=""
                        rows="2"
                        onChange={handleAddFormChange}
                        value={addFormData.result}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <div className="mb-3 row">
                  <label
                    className="col-sm-3 col-form-label mt-3"
                    style={{ fontWeight: "bold" }}
                  >
                    PTB1:
                  </label>
                  <div className="col-sm-9">
                    <div className="form-group mb-3 position-relative">
                      <textarea
                        class="form-control"
                        name="ptb1"
                        id=""
                        rows="3"
                        onChange={handleAddFormChange}
                        value={addFormData.ptb1}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    className="col-sm-3 col-form-label mt-3"
                    style={{ fontWeight: "bold" }}
                  >
                    PTB2:
                  </label>
                  <div className="col-sm-9">
                    <div className="form-group mb-3 position-relative">
                      <textarea
                        class="form-control"
                        name="ptb2"
                        id=""
                        rows="3"
                        onChange={handleAddFormChange}
                        value={addFormData.ptb2}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    className="col-sm-3 col-form-label mt-3"
                    style={{ fontWeight: "bold" }}
                  >
                    WHB1:
                  </label>
                  <div className="col-sm-9">
                    <div className="form-group mb-3 position-relative">
                      <textarea
                        class="form-control"
                        name="whb1"
                        id=""
                        rows="3"
                        onChange={handleAddFormChange}
                        value={addFormData.whb1}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label
                    className="col-sm-3 col-form-label mt-3"
                    style={{ fontWeight: "bold" }}
                  >
                    WHB2:
                  </label>
                  <div className="col-sm-9">
                    <div className="form-group mb-3 position-relative">
                      <textarea
                        class="form-control"
                        name="whb2"
                        id=""
                        rows="3"
                        onChange={handleAddFormChange}
                        value={addFormData.whb2}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="col-12" style={{ textAlign: "right" }}>
                  <button
                    type="submit"
                    className="btn btn-primary mb-2"
                    onClick={saveAssessment}
                  >
                    Submit
                  </button>
                  <button
                    type="submit"
                    onClick={cancelAssessment}
                    className="btn  mb-2"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
