import {
    getData,
    saveData,
    updateData,
    deleteData,
    getDataByPayload,
  } from "../services/Common";

  
export async function getAnalyticsSubscription() {
    const url = `/admin/analytics/subscription`;
    let response = getData(url);
    return response;
  }

    
export async function getAnalyticsJobList() {
  const url = `/admin/analytics/jobCount`;
  let response = getData(url);
  return response;
}

export async function getAnalyticsJobGraph() {
  const url = `/admin/analytics/job-graph`;
  let response = getData(url);
  return response;
}

export async function getRevenueGraph() {
  const url = `/admin/analytics/revenue-graph`;
  let response = getData(url);
  return response;
}

export async function getUserActivity() {
  const url = `/admin/analytics/user-activity`;
  let response = getData(url);
  return response;
}