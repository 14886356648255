import React, { Fragment } from "react";
import { Row, Col, Card, ProgressBar } from "react-bootstrap";

const SiteAnalytics = () => {
  return (
    <Fragment>
      <Row className="ui">
        {/* <!-- column --> */}
        <Col xl={6} lg={6} md={6}>
          <Card>
            <Card.Header className=" d-block">
              <Card.Title>Active users</Card.Title>
              <Card.Text className="mb-0 subtitle">
              100,000
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <ProgressBar now={60} variant="danger" />
            </Card.Body>
          </Card>
        </Col>
        {/* <!-- column --> */}
        <Col xl={6} lg={6} md={6}>
          <Card>
            <Card.Header className=" d-block">
              <Card.Title>Total Users</Card.Title>
              <Card.Text className="mb-0 subtitle">
                107,000
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <ProgressBar now={85} variant="info" striped />
            </Card.Body>
          </Card>
        </Col>
        {/* <!-- column --> */}
      </Row>

      <Row className="ui">
        {/* <!-- column --> */}
        <Col xl={6} lg={6} md={6}>
          <Card>
            <Card.Header className=" d-block">
              <Card.Title>Job Poster Views</Card.Title>
              <Card.Text className="mb-0 subtitle">
              1000
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <ProgressBar now={60} variant="danger" />
            </Card.Body>
          </Card>
        </Col>
        {/* <!-- column --> */}
        <Col xl={6} lg={6} md={6}>
          <Card>
            <Card.Header className=" d-block">
              <Card.Title>Job Seeker Views</Card.Title>
              <Card.Text className="mb-0 subtitle">
                100
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <ProgressBar now={85} variant="success" striped />
            </Card.Body>
          </Card>
        </Col>
        {/* <!-- column --> */}
      </Row>

    </Fragment>
  );
};

export default SiteAnalytics;
