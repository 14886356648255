import { useState, useMemo, useEffect } from 'react'
import { useReactTable, getCoreRowModel, getFilteredRowModel, 
    getFacetedRowModel, getFacetedUniqueValues, getFacetedMinMaxValues, 
    getPaginationRowModel, getSortedRowModel, flexRender
} from '@tanstack/react-table'
import { rankItem } from '@tanstack/match-sorter-utils'
import { Link } from "react-router-dom";

const fuzzyFilter = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value)

    addMeta({itemRank,})

    return itemRank.passed
}

const JobsListTable = ({_data, _onResp}) => {

const [columnFilters, setColumnFilters] = useState([])
const [globalFilter, setGlobalFilter] = useState('')
const columns = useMemo(
    () => [
    {
        header: ` `,
        footer: props => props.column.id,
        columns: [
            {
                accessorFn: row => row.option,
                id: 'option',
                cell: info => info.getValue(),
                header: () => <span>Job Title</span>,
                footer: props => props.column.id,
            },
        ],
    },
    {
        header: ` `,
        footer: props => props.column.id,
        columns: [
            {
                accessorFn: row => row.statusReal,
                id: 'statusReal',
                cell: info => info.getValue(),
                header: () => <span>status</span>,
                footer: props => props.column.id,
            },
        ],
    },
    {
        header: ` `,
        footer: props => props.column.id,
        columns: [
            {
                accessorFn: row => row.userTypeReal,
                id: 'userTypeReal',
                cell: info => info.getValue(),
                header: () => <span>User Type</span>,
                footer: props => props.column.id,
            },
        ],
    }
    ],
    []
)

const [data, setData] = useState(_data)
useEffect(() => {
    setData(_data)
}, [_data])

const table = useReactTable({
    data,
    columns,
    filterFns: {
        fuzzy: fuzzyFilter,
    },
    state: {
        columnFilters,
        globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
})

useEffect(() => {
    if (table.getState().columnFilters[0]?.id === 'fullName') {
        if (table.getState().sorting[0]?.id !== 'fullName') {
        table.setSorting([{id: 'fullName', desc: false }])
    }
    }
}, [table.getState().columnFilters[0]?.id])

const handleApproveDenied = (contact) => {
	return (contact.status == 2) ?
		<>
			<div className="btn btn-success me-1 btn-sm" onClick={() => { _onResp('', contact._id) }} data-toggle="tooltip" data-placement="top" title="Approve">
				<i class="fas fa-thumbs-up" aria-hidden="true"></i>
			</div>

			<div className="btn btn-primary me-1 btn-sm" onClick={() => { _onResp('', contact._id) }} data-toggle="tooltip" data-placement="top" title="Declined">
				<i class="fas fa-thumbs-down" aria-hidden="true"></i>
			</div>
		</>

		: (contact.status == 1) ?

			<div className="btn btn-primary me-1 btn-sm" onClick={() => { _onResp('', contact._id) }} data-toggle="tooltip" data-placement="top" title="Declined">
				<i class="fas fa-thumbs-down" aria-hidden="true"></i>
			</div>
			:
			<div className="btn btn-success me-1 btn-sm" onClick={() => { _onResp('', contact._id) }} data-toggle="tooltip" data-placement="top" title="Approve">
				<i class="fas fa-thumbs-up" aria-hidden="true"></i>
			</div>
}

return <>
<div className="table-responsive">
    <div className="h-2"></div>
    <table className='table table-sm table-hover table-striped'>
        <thead className='thead-dark'>
            {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                return (
                    <>
                    <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                        <>
                        <div
                            {...{
                            className: header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : '',
                            onClick: header.column.getToggleSortingHandler(),
                            }}
                        >
                            {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                            )}
                            {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                            }[header.column.getIsSorted()] ?? null}
                        </div>
                        {header.column.getCanFilter() ? (
                            <div>
                                <Filter column={header.column} table={table} />
                            </div>
                        ) : null}
                        </>
                    )}
                    </th>
                    </>
                )
                })}
                    <th>Actions</th>
            </tr>
            ))}
        </thead>
        <tbody>
            {table.getRowModel().rows.map(row => {
            return (
                <tr style={{'cursor': 'pointer'}} key={row.id}>
                    {row.getVisibleCells().map(cell => {
                        return (
                        <td key={cell.id} className='text-nowrap'>
                            {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                            )}
                        </td>
                        )
                    })}
                    <td>
                        {handleApproveDenied(row['original'])}
                        <Link to={"#"} className="btn btn-secondary light mr-2"
                            onClick={(event) => handleEditClick(event, row['original'])}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
                                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
                                </g>
                            </svg>
                        </Link>
                    </td>
                </tr>
            )
            })}
        </tbody>
    </table>

    <div className="h-2"></div>
    
    <div className="flex items-center gap-2">
        <button onClick={() => table.setPageIndex(0)} disabled={!table.getCanPreviousPage()}
            className="btn border rounded p-1">
            {'<<'}
        </button>
        <button onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}
            className="btn border rounded p-1">
            {'<'}
        </button>
        <button className="btn border rounded p-1"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}>
            {'>'}
        </button>
        <button className="btn border rounded p-1"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}>
            {'>>'}
        </button>
        <span className="flex items-center gap-1">
            <br />
            <strong>
            Page &nbsp;
                {table.getState().pagination.pageIndex + 1} of{' '}
                {table.getPageCount()}
            </strong>
        </span>
    </div>
</div>
</>
}

function Filter({column,table,}) {
    const firstValue = table
        .getPreFilteredRowModel()
        .flatRows[0]?.getValue(column.id)

    const columnFilterValue = column.getFilterValue()
    
    const sortedUniqueValues = useMemo(
        () =>
        typeof firstValue === 'number'
            ? []
            : Array.from(column.getFacetedUniqueValues().keys()).sort(),
        [column.getFacetedUniqueValues()]
    )

    return typeof firstValue === 'number' ? (
    <div>
        <div className="flex space-x-2">
        <DebouncedInput type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue)?.[0] ?? ''}
            onChange={value =>
                column.setFilterValue((old) => [value, old?.[1]])
            }
            placeholder={`Min ${
                column.getFacetedMinMaxValues()?.[0]
                ? `(${column.getFacetedMinMaxValues()?.[0]})`
                : ''
            }`}
            className="w-24 border shadow rounded"
        />
        <DebouncedInput
            type="number"
            min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
            max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
            value={(columnFilterValue)?.[1] ?? ''}
            onChange={value =>
                column.setFilterValue((old) => [old?.[0], value])
            }
            placeholder={`Max ${
                column.getFacetedMinMaxValues()?.[1]
                ? `(${column.getFacetedMinMaxValues()?.[1]})`
                : ''
            }`}
            className="w-24 border shadow rounded"
        />
        </div>
        <div className="h-1" />
    </div>
    ) : (
    <>
        <DebouncedInput type="text" className="w-36 border shadow rounded"
            value={(columnFilterValue ?? '')}
            onChange={value => column.setFilterValue(value)}
            placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
            list={column.id + 'list'}
        />
        <div className="h-1" />
    </>
    )
}

function DebouncedInput({value: initialValue, onChange, debounce = 500, ...props}) {
    const [value, setValue] = useState(initialValue)
    useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    useEffect(() => {
        const timeout = setTimeout(() => {
        onChange(value)
    }, debounce)
        return () => clearTimeout(timeout)
    }, [value])
    return (
        <input {...props} value={value} onChange={e => setValue(e.target.value)} className={'form-control form-control-sm bg-white text-black'}/>
    )
}

export default JobsListTable