import axios from 'axios';
import SETTINGS from '../../utils/settings';

export const postData = (url, data) => {
    // const userSessionString = sessionStorage.getItem('UserSession');
    const userToken = JSON.parse(localStorage.getItem("userDetails")).idToken;
    // const userSession = JSON.parse(userSessionString);

    const BASE_URL = SETTINGS['BACKEND_BASE_URL']
    // const token = userSession && userSession.payload ? userSession.payload.token : null;
    const endpoint = `${BASE_URL}/${url}`
    const config = {
        method: 'post',
        url: endpoint,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Accept-Language': 'en-GB,en',
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json' 
        },
        data: data,
        timeout: 500000 
    };
    if (!userToken) {
        console.error('No token found in user session.');
        return Promise.reject('Authentication token is missing.');
    }

    return axios(config);
}


export const downloadFile = (_api_path, _data) => {
    const BASE_URL = SETTINGS['BACKEND_BASE_URL']
    const userToken = JSON.parse(localStorage.getItem("userDetails")).idToken;
    const endpoint = `${BASE_URL}/${_api_path}`;
    const config = {
        method: 'post',
        url: endpoint,
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Accept-Language': 'en-GB,en',
            'Authorization': `Bearer ${userToken}`,
            'Content-Type': 'application/json'
        },
        responseType: 'blob', // Important to handle binary data
        data: _data
    };

    if (!userToken) {
        console.error('No token found in user session.');
        return Promise.reject('Authentication token is missing.');
    }

    return axios(config)
};