import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import swal from "sweetalert";
import { getRolesPayload,addRole,deleteRole,updateRole,getRoleQuestionsPayload,updateRoleQuestion,ruleApprove,ruleDisapprove} from  "../../../services/RoleServices"
import Alert from "sweetalert2";

const CompanySize = () => {
	
	//Modal box
	const page = "companysize";
	const questionTitle = "Company Size"

	const payload = { 
		category:page
	}
	const [contacts, setContacts] = useState();
	const [editQuestion, setEditQuestion] = useState({
		jobPoster: false,
		jobSeeker:false
	});
	const [roleQuestion, setRoleQuestion] = useState();
	const [jobPosterQuestion, setJobPosterQuestion] = useState();
	const [jobSeekerQuestion, setJobSeekerQuestion] = useState();
	const [questionError, setQuestionError] = useState({
		jobPoster: false,
		jobSeeker:false
	});


	const handlePosterQuestion = async() => { 
		if (jobPosterQuestion.question.trim() ==="") {
			setQuestionError({ jobPoster: true, jobSeeker:questionError.jobSeeker})
		} else { 
			setQuestionError({ jobPoster: false, jobSeeker:questionError.jobSeeker})
			const response = await updateRoleQuestion(jobPosterQuestion,roleQuestion['0']._id);
			if (response.status == 200) {
				swal('Good job!', response.data.message, "success");
				getRoleQuestion()
				setEditQuestion({jobPoster:false,jobSeeker:editQuestion.jobSeeker})
			} else { 
				swal('Oops', "Some error...", "error");
			}
		}
	}

	const handleSeekerQuestion = async() => { 
		if (jobSeekerQuestion.question.trim() ==="") {
			setQuestionError({ jobSeeker: true, jobPoster:questionError.jobPoster})
		} else { 
			setQuestionError({ jobSeeker: false, jobPoster:questionError.jobPoster})
			const response = await updateRoleQuestion(jobSeekerQuestion,roleQuestion['1']._id);
			if (response.status == 200) {
				swal('Good job!', response.data.message, "success");
				getRoleQuestion()
				setEditQuestion({jobSeeker:false,jobPoster:editQuestion.jobPoster})
			} else { 
				swal('Oops', "Some error...", "error");
			}
		}
	}
	async function getRole() {
		const response = await getRolesPayload(payload);
		 if (response.status == 200) {
		 	setContacts(response.data.result)
		 } 
	}
	async function getRoleQuestion() {
		const response = await getRoleQuestionsPayload(payload);
		if (response.status == 200) {
			setRoleQuestion(response.data.result)
			setJobSeekerQuestion({question:response.data.result['1'].question})
			setJobPosterQuestion({question:response.data.result['0'].question})
		} 
	}
	useEffect(() => {
		getRoleQuestion();
		getRole();
	}, []);
	
	return(
		<>
			<div className="d-flex align-items-center mb-4 flex-wrap">
				<h4 className="fs-20 font-w600  me-auto">{questionTitle}</h4>
			</div>	
			{(roleQuestion) ?
				<div className="row card mx-0">
					<div className="col-xl-12">
						<div className="mb-1 row">
							<div className="col-sm-3 pt-2">
								<label
									className="col-sm-12 col-form-label mt-3"
									style={{ fontWeight: "bold" }}
								>
								<h4>{questionTitle} Question (Job poster):</h4> 
								</label>
							</div>
							
							<div className="col-sm-6 pt-2">
								{(editQuestion.jobPoster) ?
									<>
										<input
									type="text"
									className="form-control"
									value={ jobPosterQuestion.question}
									onChange={(e) => { setJobPosterQuestion({question:e.target.value})}}
									placeholder="Enter your Question Here..."
										/>
									<span className="validation-text text-danger">{ (questionError.jobPoster)?"Please Enter Question":null}</span>
										
								</>
									
									
									: <h4 className="pt-3">{roleQuestion['0'].question}</h4>}
								
							</div>
							<div className="col-sm-3 pt-2">
								{(editQuestion.jobPoster) ?
									<>.
									<button type="submit" onClick={handlePosterQuestion} className="btn btn-secondary" >Save</button>   
									<button onClick={() => { setEditQuestion({jobPoster:false,jobSeeker:editQuestion.jobSeeker})}} className="btn btn-danger ms-3" >Cancel</button> </>
									:
									<Link to={"#"} className="btn btn-secondary light mr-2"
										onClick={() => { setEditQuestion({jobPoster:true,jobSeeker:editQuestion.jobSeeker})}}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
											<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
												<rect x="0" y="0" width="24" height="24"></rect>
												<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
												<rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
											</g>
										</svg>
									</Link>
								}
							</div>
						</div>
					</div>
					<div className="col-xl-12">
						<div className="mb-1 row">
							<div className="col-sm-3 pt-2">
								<label
									className="col-sm-12 col-form-label mt-3"
									style={{ fontWeight: "bold" }}
								>
								<h4>{questionTitle} Question (Job seeker):</h4> 
								</label>
							</div>
							
							<div className="col-sm-6 pt-2">
								{(editQuestion.jobSeeker) ?
									<>
										<input
									type="text"
									className="form-control"
									value={ jobSeekerQuestion.question}
									onChange={(e) => { setJobSeekerQuestion({question:e.target.value})}}
									placeholder="Enter your Question Here..."
										/>
									<span className="validation-text text-danger">{ (questionError.jobSeeker)?"Please Enter Question":null}</span>
										
								</>
									
									
									: <h4 className="pt-3">{roleQuestion['1'].question}</h4>}
								
							</div>
							<div className="col-sm-3 pt-2">
								{(editQuestion.jobSeeker) ?
									<>
									<button type="submit" onClick={handleSeekerQuestion} className="btn btn-secondary" >Save</button>   
									<button onClick={() => { setEditQuestion({jobPoster:editQuestion.jobPoster,jobSeeker:false})}} className="btn btn-danger ms-3" >Cancel</button>   
									</>
									:
									<Link to={"#"} className="btn btn-secondary light mr-2"
										onClick={() => { setEditQuestion({jobPoster:editQuestion.jobPoster,jobSeeker:true})}}>
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
											<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
												<rect x="0" y="0" width="24" height="24"></rect>
												<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
												<rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
											</g>
										</svg>
									</Link>
								}
							</div>
						</div>
					</div>
				</div>
				
				: null}
		</>
	)		
}
export default CompanySize;