import { postData, downloadFile } from "./requests";

export const getSeakerByObj = (_seeker_id) => {
    const url = 'jobposter/fetchjobposter';
    const requestData = {
        'userId': _seeker_id,
        'usertype': 1
    };
    return postData(url, requestData)
}

export const downloadSeekerCvByLink = (_link) => {
    const url = 'jobseeker/download/user-csv';
    const requestData = {
        'link': _link,
    };
    return downloadFile(url, requestData)
}