/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from "./Logout";
/// Image
import profile from "../../../images/profile/pic1.jpg";
import { adminEmail } from "../../../services/Common";
import { getAdminInfo } from "../../../services/AuthService";
import swal from "sweetalert";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() { }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}
const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const [profilePath, setProfilePath] = useState(profile);
  const [profileName, setProfileName] = useState("");

  const getAdmininfoData = async () => {
    const payload = {
      email: adminEmail,
    };
    const data = await getAdminInfo(payload);
    if (data.status === 200) {
      // console.log("Admin", data);
      setProfileName(data.data.user.fullName);
      if (
        data.data.user &&
        data.data.user.profilePicture != "" &&
        data.data.user.profilePath != ""
      ) {
        if (typeof data.data.user.profilePath == "undefined") {
          setProfilePath(
            "https://t4.ftcdn.net/jpg/02/17/34/67/240_F_217346782_7XpCTt8bLNJqvVAaDZJwvZjm0epQmj6j.jpg"
          );
        } else {
          let profilePath = `${data.data.user.profilePath}`;
          setProfilePath(profilePath);
        }
      }
    } else {
      swal("Oops", "Some error to fetch error", "error");
    }
  };
  // console.log(profilePath);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
    setTimeout(() => {
      getAdmininfoData();
    }, 500);
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let selfAssessment = [
    "add-self-Assessment",
    "edit-self-Assessmen",
    "self-Assessment",
  ],
    onboarding = [
      "Culture",
      "job-title",
      "industry",
      "education-level",
      "skill-level",
      "soft-skill",
      "hard-skill",
      "location",
      "company-size",
      "employment-type",
      "salary",
      "hour",
    ];
  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? scrollPosition > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <Dropdown as="div" className=" header-profile2 dropdown">
          <Dropdown.Toggle
            variant=""
            as="a"
            className="nav-link i-false c-pointer"
            role="button"
            data-toggle="dropdown"
          >
            <div className="header-info2 d-flex align-items-center">
              <img
                src={profilePath}
                style={{ objectFit: "cover" }}
                width={20}
                alt="Profile"
              />
              <div className="d-flex align-items-center sidebar-info">
                <div>
                  <span className="font-w400 d-block">{profileName}</span>
                  <small className="text-end font-w400">{profileName}</small>
                </div>
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu
            align="right"
            className=" dropdown-menu dropdown-menu-end"
          >
            <LogoutPage />
            <Link className="dropdown-item ai-icon" to="/edit-profile">
              <i className="fas fa-user-circle text-danger"></i>
              <span className="ms-2">Edit Profile</span>
            </Link>
          </Dropdown.Menu>
        </Dropdown>
        <MM className="metismenu" id="menu">
          <li className={`${path === "dashboard" ? "mm-active" : ""}`}>
            <Link className="" to="/dashboard">
              <i className="flaticon-025-dashboard"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>
          <li
            className={`${path === "job-list" || path === "detail-seeker" ? "mm-active" : ""
              }`}
          >
            <Link className="" to="/job-list">
              <i className="flaticon-093-waving"></i>
              <span className="nav-text">Job seeker</span>
            </Link>
          </li>

          <li
            className={`${path === "poster-list" || path === "detail-poster"
              ? "mm-active"
              : ""
              }`}
          >
            <Link className="" to="/poster-list">
              <i className="flaticon-050-info"></i>
              <span className="nav-text">Job poster</span>
            </Link>
          </li>

          <li
            className={`${path === "list-personal-Builder" ||
              path === "add-personal-Builder" ||
              path === "edit-personal-Builder"
              ? "mm-active"
              : ""
              }`}
          >
            <Link className="" to="/list-personal-Builder">
              <i className="flaticon-050-info"></i>
              <span className="nav-text">Personal Builder</span>
            </Link>
          </li>

          <li className={`${selfAssessment.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-050-info"></i>
              <span className="nav-text">Self assessment</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "self-Assessment" ? "mm-active" : ""}`}
                  to="/self-Assessment"
                >
                  Self assessment List
                </Link>
              </li>
            </ul>
          </li>

          <li className={`${onboarding.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-041-graph"></i>
              <span className="nav-text">On-boarding</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "Culture" ? "mm-active" : ""}`}
                  to="/Culture"
                >
                  Culture
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "job-title" ? "mm-active" : ""}`}
                  to="/job-title"
                >
                  Job title
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "industry" ? "mm-active" : ""}`}
                  to="/industry"
                >
                  Job industry
                </Link>
              </li>

              <li>
                <Link
                  className={`${path === "education-level" ? "mm-active" : ""}`}
                  to="/education-level"
                >
                  Education level
                </Link>
              </li>

              <li>
                <Link
                  className={`${path === "skill-level" ? "mm-active" : ""}`}
                  to="/skill-level"
                >
                  Job Level
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "company-size" ? "mm-active" : ""}`}
                  to="/company-size"
                >
                  Company size
                </Link>
              </li>

              <li>
                <Link
                  className={`${path === "employment-type" ? "mm-active" : ""}`}
                  to="/employment-type"
                >
                  Employment type
                </Link>
              </li>

              <li>
                <Link
                  className={`${path === "salary" ? "mm-active" : ""}`}
                  to="/salary"
                >
                  Salary
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "soft-skill" ? "mm-active" : ""}`}
                  to="/soft-skill"
                >
                  Soft Skill
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "hard-skill" ? "mm-active" : ""}`}
                  to="/hard-skill"
                >
                  Hard Skill
                </Link>
              </li>
            </ul>
          </li>

          <li className={`${path === "point-system" ? "mm-active" : ""}`}>
            <Link className=" ai-icon" to="/point-system">
              <i className="flaticon-086-star"></i>
              <span className="nav-text">Point System</span>
            </Link>
          </li>
          <li className={`${path === "plan" ? "mm-active" : ""}`}>
            <Link className="" to="/plan">
              <i className="flaticon-043-menu"></i>
              <span className="nav-text">Subscription plans</span>
            </Link>
          </li>


        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
