import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { getUserInfoById as getUserInfoByIdApi, 
    updateUserInfo as updateUserInfoApi } from "../../../api/private/users";
import { da } from "date-fns/locale";

const UserDetailsModal = ({_show, _onResp, _userId}) => {
const baseSaveText = 'Save'
const [btnSaveText, setBtnSaveText] = useState(baseSaveText)
const [userDetails, setUserDetails] = useState(null)
const [loading, setLoading] = useState(false)

useEffect(() => {
    loadUserByUid()
}, [_userId])

function loadUserByUid(){
    if(_userId === null){return null}
    getUserInfoByIdApi(_userId).then((_resp) => {
        if(_resp.data.messgae === 'Success'){
            const data = _resp.data.posterInfo
            setUserDetails(data)
        }else{

        }
    }).catch((_resp) => {
        console.error(_resp)
    }).finally(() => {

    })
    // TODO dont know what api to user for this?
}

function onUpdateClick(){
    setBtnSaveText('Saving')
    updateUserInfoApi(userDetails).then((_resp) => {
        
    }).catch((_resp) => {

    }).finally(() => {
        setBtnSaveText(baseSaveText)
    })
}

function onKeyValueChange(_key, _value){
    let tmp = {...userDetails}
    tmp[_key] = _value
    setUserDetails(tmp)
}

return <>
<Modal className="fade" show={_show} size="lg">
    <Modal.Header>
        {userDetails !== null?
            <Modal.Title>{userDetails['fullName']}</Modal.Title>
        : null}
        <Button onClick={() => _onResp('close', null)} className="btn-refresh" variant=""></Button>
        <Button onClick={() => _onResp('close', null)} className="btn-close" variant=""></Button>
    </Modal.Header>
    <Modal.Body>
        {userDetails !== null?
            <div className="row">
                <div className="col-lg-6">
                    <div className="card border border-dark rounded p-2">
                        <ol className="list-group list-group-numbered">
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto w-100">
                                <div className="fw-bold">FullName</div>
                                    <input onChange={(e) => onKeyValueChange('fullName', e.target.value)} value={userDetails['fullName']} type="text" className="form-control border-bottom border-dark"/>
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto w-100">
                                <div className="fw-bold">Email</div>
                                    <input onChange={(e) => onKeyValueChange('email', e.target.value)} value={userDetails['email']} type="text" className="form-control  border-bottom border-dark"/>
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto w-100">
                                <div className="fw-bold">Contact Number</div>
                                    <input onChange={(e) => onKeyValueChange('contactNumber', e.target.value)} value={userDetails['contactNumber']} placeholder="+27723720034" type="text" className="form-control  border-bottom border-dark"/>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="card border border-dark rounded p-2">
                        <ol className="list-group list-group-numbered">
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto w-100">
                                <div className="fw-bold">Company Name</div>
                                    <input onChange={(e) => onKeyValueChange('companyName', e.target.value)} value={userDetails['companyName']} placeholder="company name" type="text" className="form-control  border-bottom border-dark"/>
                                </div>
                            </li>
                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                <div className="ms-2 me-auto w-100">
                                <div className="fw-bold">Image</div>
                                    <img src={userDetails['image']} className="img-fluid" alt="" />
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        : null}
    </Modal.Body>
    <Modal.Footer>
        <Button onClick={() => _onResp('close', null)} variant="danger light">Close</Button>
        <Button onClick={() => onUpdateClick()} disabled={btnSaveText !== baseSaveText} variant="primary">{btnSaveText}</Button>
    </Modal.Footer>
</Modal>
</>
}

export default UserDetailsModal