import React, { useState, useContext, useEffect } from "react";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Nav, Tab } from "react-bootstrap";

//Import Components
import { ThemeContext } from "../../../context/ThemeContext";
import VacanyTab from "../Jobick/Home/VacanyTab";
import CanvasChartTab from "../Jobick/Home/CanvasChartTab";
import {
  getAnalyticsJobList,
  getAnalyticsSubscription,
} from "../../../services/analytics";

//Components

const ApexPieChart = loadable(() =>
  pMinDelay(import("../Jobick/Home/ApexPieChart"), 1000)
);
// const ApexPieChart2 = loadable(() =>
//   pMinDelay(import("../Jobick/Home/ApexPieChart2"), 1000)
// );
const ApexPieChart3 = loadable(() =>
  pMinDelay(import("../Jobick/Home/ApexPieChart3"), 1000)
);
// const ApexPieChart4 = loadable(() =>
//   pMinDelay(import("../Jobick/Home/ApexPieChart4"), 1000)
// );

const Home = () => {
  const [jobData, setJobList] = useState([]);

  const [creditPurchased, setCreditPurchased] = useState([]);

  const getAnalyticsSubscriptionList = async () => {
    let payload = "";
    const response = await getAnalyticsSubscription(payload);
    if (response.status == 200) {
      setCreditPurchased(response.data);
    }
    const JobList = await getAnalyticsJobList(payload);
    if (JobList.status == 200) {
      setJobList(JobList.data);
    }
  };

  const { changeBackground } = useContext(ThemeContext);
  useEffect(() => {
    getAnalyticsSubscriptionList();
    changeBackground({ value: "light", label: "Light" });
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header border-0 pb-0 flex-wrap">
                  <h4 className="fs-20 font-w500 mb-1 text-capitalize">
                    Credits
                  </h4>
                  {/* <div
                    className="card-action coin-tabs mt-3 mt-sm-0"
                    defaultActiveKey="Monthly"
                  >
                    <Tab.Container defaultActiveKey="Daily">
                      <Nav as="ul" className="nav nav-tabs" role="tablist">
                        <Nav.Item as="li" className="nav-item">
                          <Nav.Link className="nav-link " eventKey="Daily">
                            Daily
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item">
                          <Nav.Link className="nav-link" eventKey="Weekly">
                            Weekly
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="nav-item">
                          <Nav.Link className="nav-link" eventKey="Monthly">
                            Monthly
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Tab.Container>
                  </div> */}
                </div>
                <div className="card-body">
                  <div className="row separate-row">
                    <div className="col-sm-6">
                      <div className="job-icon pb-4 d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center mb-1">
                            <h2 className="mb-0">
                              {creditPurchased.totalCreditBuy}
                            </h2>
                          </div>
                          <span className="fs-14 d-block mb-2">
                            Total number of Credits bought
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center mb-1">
                            <h2 className="mb-0">
                              {creditPurchased.DirectProfileBuy}
                            </h2>
                          </div>
                          <span className="fs-14 d-block mb-2">
                            Total number of direct profiles bought
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center mb-1">
                            <h2 className="mb-0">
                              {creditPurchased.creditUsedProfile}
                            </h2>
                          </div>
                          <span className="fs-14 d-block mb-2">
                            Total number of credits used
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center mb-1">
                            <h2 className="mb-0">
                              {creditPurchased.totalIncome}
                            </h2>
                          </div>
                          <span className="fs-14 d-block mb-2">
                            Total Income
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <VacanyTab />
            </div>
            <div className="col-xl-12">
              <CanvasChartTab />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row">
            <div className="card">
              <div className="card-header border-0 pb-0 flex-wrap">
                <h4 className="fs-20 font-w500 mb-1 text-capitalize">Jobs</h4>
              </div>
              <div className="card-body">
                <div className="row separate-row">
                  <div className="col-sm-6">
                    <div className="job-icon pb-4 d-flex justify-content-between">
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h2 className="mb-0">
                            {jobData?.totalJob ? jobData?.totalJob : 0}
                          </h2>
                        </div>
                        <span className="fs-14 d-block mb-2">
                          Total Jobs posted
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h2 className="mb-0">
                            {jobData.CloseJob ? jobData?.CloseJob : 0}
                          </h2>
                        </div>
                        <span className="fs-14 d-block mb-2">
                          Total Inactive jobs
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h2 className="mb-0">
                            {jobData.openJob ? jobData?.openJob : 0}
                          </h2>
                        </div>
                        <span className="fs-14 d-block mb-2">
                          Total number of available jobs
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
                      <div>
                        <div className="d-flex align-items-center mb-1">
                          <h2 className="mb-0">1,567k</h2>
                        </div>
                        <span className="fs-14 d-block mb-2">
                          Graph for closed jobs vs open jobs
                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card positon-relative">
                <div className="card-body">
                  <h4 className="fs-20 mb-5 ">Job Seeker</h4>
                  <div className="row mt-4 align-items-center">
                    <div className="col-xl-6 col-sm-6 position-relative">
                      <div id="pieChart1">
                        <ApexPieChart />
                      </div>
                    </div>
                    {/* <div className="col-xl-6 col-sm-6 position-relative">
											<h2 className="fs-20 mb-3 text-center">Job Seeker char2</h2>
											<div id="pieChart1">
												<ApexPieChart2 />
											</div>
										</div> */}
                  </div>
                  <div className="card-body">
                    <h2 className="fs-20 mb-5 ">Job Poster</h2>
                    <div className="col-xl-6 col-sm-6 position-relative">
                      <div id="pieChart1">
                        <ApexPieChart3 />
                      </div>
                    </div>
                    {/* <div className="col-xl-6 col-sm-6 h-100">
											<h2 className="fs-20 mb-3 text-center">Job Poster char2</h2>
											<div id="pieChart1">
												<ApexPieChart4 />
											</div>
										</div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
