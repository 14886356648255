import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import {
  deletePlan,
  getPlanLists,
  savePlan,
  statusChangePlan,
  updatePlan,
} from '../../../services/PlanController';
import Alert from 'sweetalert2';

const TableContent = [];

const SubscribePlan = () => {
  //Modal box
  const [addCard, setAddCard] = useState(false);

  const [contacts, setContacts] = useState(TableContent);

  // delete data
  const handleDeleteClick = contactId => {
    Alert.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async result => {
      if (result.isConfirmed) {
        let response = await deletePlan(contactId);
        if (response.status == 200) {
          getPlanList();
          Alert.fire('Deleted!', 'Your data has been deleted.', 'success');
        } else {
          swal('Oops', 'Some error...', 'error');
        }
      }
    });
  };

  //Add data
  const [addFormData, setAddFormData] = useState({
    baseprice: '',
    offerprice: '',
    description: '',
    title: '',
    credit: '0',
    status: true,
  });

  // Add contact function
  const handleAddFormChange = event => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  //Add Submit data
  const handleAddFormSubmit = async event => {
    event.preventDefault();
    var error = false;
    var errorMsg = '';
    if (addFormData.offerprice === '') {
      error = true;
      errorMsg = 'Please fill offer price';
    } else if (addFormData.description === '') {
      error = true;
      errorMsg = 'Please fill description';
    } else if (addFormData.title === '') {
      error = true;
      errorMsg = 'Please fill title';
    } else if (addFormData.credit === '') {
      error = true;
      errorMsg = 'Please fill credit';
    } else if (addFormData.status === '') {
      error = true;
      errorMsg = 'Please write status';
    }
    if (!error) {
      const newContact = {
        // baseprice: addFormData.baseprice,
        offerprice: addFormData.offerprice,
        description: addFormData.description,
        credit: addFormData.credit,
        title: addFormData.title,
        custom: addFormData.custom,
        status: addFormData.status,
      };
      let result = await savePlan(newContact);
      if (result.status === 200) {
        swal('Good job!', 'Successfully Updated', 'success');
        setTimeout(() => {
          getPlanList();
        }, 100);
        setAddCard(false);
        addFormData.baseprice = addFormData.offerprice = addFormData.description = '';
        addFormData.credit = '0';
        addFormData.status = true;
      } else {
        swal('Oops', 'Something went wrong', 'error');
      }
    } else {
      swal('Oops', errorMsg, 'error');
    }
  };

  //Edit start
  const [editModal, setEditModal] = useState(false);
  // Edit function editable page loop
  const [editContactId, setEditContactId] = useState(null);

  // Edit function button click to edit
  const handleEditClick = (event, contact) => {
    event.preventDefault();
    setEditContactId(contact._id);
    const formValues = {
      baseprice: contact.baseprice,
      offerprice: contact.offerprice,
      description: contact.description,
      title: contact.title,
      credit: contact.credit,
      custom: contact.custom,
      status: contact.status,
    };
    setEditFormData(formValues);
    setEditModal(true);
  };

  // edit  data
  const [editFormData, setEditFormData] = useState({
    baseprice: '',
    offerprice: '',
    credit: '',
    custom: '',
    title: '',
    description: '',
    status: '',
  });

  //update data function
  const handleEditFormChange = event => {
    event.preventDefault();
    const fieldName = event.target.getAttribute('name');
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  // edit form data submit
  const handleEditFormSubmit = async event => {
    event.preventDefault();
    const editedContact = {
      id: editContactId,
      title: editFormData.title,
      baseprice: editFormData.baseprice,
      offerprice: Number(editFormData.offerprice),
      description: editFormData.description,
      credit: editFormData.credit,
      custom: editFormData.custom,
      status: editFormData.status,
    };
    const result = await updatePlan(editedContact);
    if (result.status === 200) {
      swal('Good job!', 'Successfully Updated', 'success');
      getPlanList();
      setEditContactId(null);
      setEditModal(false);
    } else {
      swal('Oops', 'Something went wrong', 'error');
    }
  };
  const handleStatus = async (id, status) => {
    let payload = {
      id: id,
      status: status,
    };
    const response = await statusChangePlan(payload);
    if (response.status == 200) {
      swal('Good job!', response.data.message, 'success');
      getPlanList();
    } else {
      swal('Oops', 'Some error...', 'error');
    }
  };
  const getPlanList = async () => {
    let payload = '';
    const response = await getPlanLists(payload);
    if (response.status == 200) {
      setContacts(response.data.result);
    }
  };
  useEffect(() => {
    getPlanList();
  }, []);
  return (
    <>
      <div className='d-flex align-items-center mb-4 flex-wrap'>
        <h4 className='fs-20 font-w600  me-auto'>Subscription plans</h4>
        <div>
          <Link to={'#'} className='btn btn-primary me-3 btn-sm' onClick={() => setAddCard(true)}>
            <i className='fas fa-plus me-2'></i>Add New Subscription plan
          </Link>
          <Modal className='modal fade' show={addCard} onHide={setAddCard}>
            <div role='document'>
              <div className=''>
                <form>
                  <div className='modal-header'>
                    <h4 className='modal-title fs-20'>Add Subscription plan</h4>
                    <button
                      type='button'
                      className='btn-close'
                      onClick={() => setAddCard(false)}
                      data-dismiss='modal'
                    >
                      <span></span>
                    </button>
                  </div>
                  <div className='modal-body'>
                    <i className='flaticon-cancel-12 close' data-dismiss='modal'></i>
                    <div className='add-contact-box'>
                      <div className='add-contact-content'>
                        {/* <div className='form-group mb-3'>
                          <label className='text-black font-w500'>Base Price</label>
                          <div className='contact-name'>
                            <input
                              type='number'
                              className='form-control'
                              autocomplete='off'
                              name='baseprice'
                              required='required'
                              onChange={handleAddFormChange}
                              placeholder='Base Price'
                            />
                            <span className='validation-text'></span>
                          </div>
                        </div> */}

                        <div className='form-group mb-3'>
                          <label className='text-black font-w500'>Price</label>
                          <div className='contact-name'>
                            <input
                              type='number'
                              className='form-control'
                              autocomplete='off'
                              name='offerprice'
                              required='required'
                              onChange={handleAddFormChange}
                              placeholder='Price'
                            />
                            <span className='validation-text'></span>
                          </div>
                        </div>
                        <div className='form-group mb-3'>
                          <label className='text-black font-w500'>Title</label>
                          <div className='contact-name'>
                            <input
                              type='text'
                              className='form-control'
                              autocomplete='off'
                              name='title'
                              required='required'
                              onChange={handleAddFormChange}
                              placeholder='Enter title'
                            />
                            <span className='validation-text'></span>
                          </div>
                        </div>
                        <div className='form-group mb-3'>
                          <label className='text-black font-w500'>Description</label>
                          <div className='contact-name'>
                            <input
                              type='text'
                              className='form-control'
                              autocomplete='off'
                              name='description'
                              required='required'
                              onChange={handleAddFormChange}
                              placeholder='Enter Description'
                            />
                            <span className='validation-text'></span>
                          </div>
                        </div>

                        <div className='form-group mb-3'>
                          <label className='text-black font-w500'>credit</label>
                          <div className='contact-name position-relative'>
                            <input
                              type='text'
                              className='form-control'
                              name='credit'
                              onChange={handleAddFormChange}
                            ></input>
                          </div>
                        </div>
                        <div className='form-group mb-3'>
                          <label className='text-black font-w500'>Status</label>
                          <div className='contact-name position-relative'>
                            <i
                              class='fa fa-angle-down position-absolute start-3'
                              aria-hidden='true'
                              style={{ fontSize: '25px', right: '25px', top: '18px' }}
                            ></i>

                            <select
                              defaultValue={true}
                              name='status'
                              onChange={handleAddFormChange}
                              className='form-control form-control-lg'
                            >
                              <option value={true}>Active</option>
                              <option value={false}>In active</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button type='submit' className='btn btn-success' onClick={handleAddFormSubmit}>
                      Add
                    </button>
                    <button
                      type='button'
                      onClick={() => setAddCard(false)}
                      className='btn btn-danger'
                    >
                      {' '}
                      <i className='flaticon-delete-1'></i> Discard
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
          <Modal className='modal fade' show={editModal} onHide={setEditModal}>
            <div role='document'>
              <div>
                <form>
                  <div className='modal-header'>
                    <h4 className='modal-title fs-20'>Edit Subscription plan</h4>
                    <button
                      type='button'
                      className='btn-close'
                      onClick={() => setEditModal(false)}
                      data-dismiss='modal'
                    >
                      <span></span>
                    </button>
                  </div>
                  <div className='modal-body'>
                    <i className='flaticon-cancel-12 close' data-dismiss='modal'></i>
                    <div className='add-contact-box'>
                      <div className='add-contact-content'>
                        {/* <div className='form-group mb-3'>
                          <label className='text-black font-w500'>Base Price</label>
                          <div className='contact-name'>
                            <input
                              type='number'
                              className='form-control'
                              autocomplete='off'
                              name='baseprice'
                              required='required'
                              value={editFormData.baseprice}
                              onChange={handleEditFormChange}
                              placeholder='Base Price'
                            />
                            <span className='validation-text'></span>
                          </div>
                        </div> */}

                        <div className='form-group mb-3'>
                          <label className='text-black font-w500'>Price</label>
                          <div className='contact-name'>
                            <input
                              type='number'
                              className='form-control'
                              autocomplete='off'
                              name='offerprice'
                              required='required'
                              value={editFormData.offerprice}
                              onChange={handleEditFormChange}
                              placeholder='Price'
                            />
                            <span className='validation-text'></span>
                          </div>
                        </div>
                        <div className='form-group mb-3'>
                          <label className='text-black font-w500'>Title</label>
                          <div className='contact-name'>
                            <input
                              type='text'
                              className='form-control'
                              autocomplete='off'
                              name='title'
                              required='required'
                              value={editFormData.title}
                              onChange={handleEditFormChange}
                              placeholder='Enter Title'
                            />
                            <span className='validation-text'></span>
                          </div>
                        </div>
                        <div className='form-group mb-3'>
                          <label className='text-black font-w500'>Description</label>
                          <div className='contact-name'>
                            <input
                              type='text'
                              className='form-control'
                              autocomplete='off'
                              name='description'
                              required='required'
                              value={editFormData.description}
                              onChange={handleEditFormChange}
                              placeholder='Enter Description'
                            />
                            <span className='validation-text'></span>
                          </div>
                        </div>
                        <div className='form-group mb-3'>
                          <label className='text-black font-w500'>credit</label>
                          <div className='contact-name position-relative'>
                            <input
                              type='text'
                              defaultValue={editFormData.credit}
                              name='credit'
                              disabled={editFormData.credit < 1 ? true : false}
                              onChange={handleEditFormChange}
                              className='form-control'
                            ></input>
                          </div>
                        </div>
                        <div className='form-group mb-3'>
                          <label className='text-black font-w500'>Status</label>
                          <div className='contact-name position-relative'>
                            <i
                              class='fa fa-angle-down position-absolute start-3'
                              aria-hidden='true'
                              style={{ fontSize: '25px', right: '25px', top: '18px' }}
                            ></i>

                            <select
                              defaultValue={editFormData.status}
                              name='status'
                              onChange={handleEditFormChange}
                              className='form-control form-control-lg'
                            >
                              <option value={true}>Active</option>
                              <option value={false}>In active</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='submit'
                      className='btn btn-primary'
                      onClick={handleEditFormSubmit}
                    >
                      Save
                    </button>
                    <button
                      type='button'
                      onClick={() => setEditModal(false)}
                      className='btn btn-danger'
                    >
                      {' '}
                      <i className='flaticon-delete-1'></i> Discard
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
        </div>
      </div>
      <div className='row'>
        <div className='col-xl-12'>
          <div className='table-responsive'>
            <table className='table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer'>
              <thead>
                <tr>
                  <th>No</th>
                  {/* <th>Base Price</th> */}
                  <th>Price</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>credit</th>
                  <th>Status</th>
                  <th className='action-buttons d-flex justify-content-end me-3'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {contacts?.map((contact, index) => (
                  <tr key={index}>
                    <td>#{index + 1}</td>
                    {/* <td>{contact.baseprice}</td> */}
                    <td>{contact.offerprice}</td>
                    <td>{contact.title}</td>
                    <td>{contact.description.slice(0, 20)}...</td>
                    <td>
                      {contact.credit == 'Custom' ? `${contact.custom} Days` : contact.credit}
                    </td>
                    <td>
                      <span className='badge badge-primary badge-lg light'>
                        {contact.status ? 'Active' : 'In active'}
                      </span>
                    </td>
                    <td>

                      < div className='action-buttons d-flex justify-content-end'>
                        {contact.credit < 1 ? <div></div> :

                          contact.status ? (
                            <div
                              className='btn btn-primary me-1 btn-sm'
                              onClick={() => {
                                handleStatus(contact._id, false);
                              }}
                              data-toggle='tooltip'
                              data-placement='top'
                              title='Declined'
                            >
                              <i class='fas fa-thumbs-down' aria-hidden='true'></i>
                            </div>
                          ) : (
                            <div
                              className='btn btn-success me-1 btn-sm'
                              onClick={() => {
                                handleStatus(contact._id, true);
                              }}
                              data-toggle='tooltip'
                              data-placement='top'
                              title='Approve'
                            >
                              <i class='fas fa-thumbs-up' aria-hidden='true'></i>
                            </div>
                          )
                        }
                        <Link
                          to={'#'}
                          className='btn btn-secondary light mr-2'
                          onClick={event => handleEditClick(event, contact)}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24px'
                            height='24px'
                            viewBox='0 0 24 24'
                            version='1.1'
                            className='svg-main-icon'
                          >
                            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                              <rect x='0' y='0' width='24' height='24'></rect>
                              <path
                                d='M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z'
                                fill='#000000'
                                fillRule='nonzero'
                                transform='translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) '
                              ></path>
                              <rect
                                fill='#000000'
                                opacity='0.3'
                                x='5'
                                y='20'
                                width='15'
                                height='2'
                                rx='1'
                              ></rect>
                            </g>
                          </svg>
                        </Link>
                        {contact.credit < 1 ? <div></div> :
                          <Link
                            to={'#'}
                            className='btn btn-danger light'
                            onClick={() => handleDeleteClick(contact._id)}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24px'
                              height='24px'
                              viewBox='0 0 24 24'
                              version='1.1'
                              className='svg-main-icon'
                            >
                              <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                <rect x='0' y='0' width='24' height='24'></rect>
                                <path
                                  d='M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z'
                                  fill='#000000'
                                  fillRule='nonzero'
                                ></path>
                                <path
                                  d='M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z'
                                  fill='#000000'
                                  opacity='0.3'
                                ></path>
                              </g>
                            </svg>
                          </Link>}
                      </div>

                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
export default SubscribePlan;
