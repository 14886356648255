import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

class CanvasChart2 extends Component {
  render() {
    var activityData = [this.props.data];

    const data = {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Credits",
          backgroundColor: "#F73A0B",
          borderColor: "var(--primary)",
          data: activityData[this.props.dataActive],
          borderWidth: 0,
          barThickness: "flex",
          minBarLength: 10,
          barPercentage: 0.3,
        },
      ],
    };
    const options = {
      responsive: true,
      maintainAspectRatio: false,

      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            gridLines: {
              color: "rgba(255,255,255,0.2)",
              drawBorder: true,
            },
            ticks: {
              fontColor: "#6E6E6E",
              max: 50000,
              min: 0,
              stepSize: 20,
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
              zeroLineColor: "transparent",
            },
            ticks: {
              stepSize: 20,
              fontColor: "#6E6E6E",
              fontFamily: "Nunito, sans-serif",
            },
          },
        ],
      },
      tooltips: {
        mode: "index",
        intersect: false,
        titleFontColor: "#888",
        bodyFontColor: "#555",
        titleFontSize: 12,
        bodyFontSize: 15,
        backgroundColor: "rgba(255,255,255,1)",
        displayColors: true,
        xPadding: 10,
        yPadding: 7,
        borderColor: "rgba(220, 220, 220, 1)",
        borderWidth: 1,
        caretSize: 6,
        caretPadding: 10,
      },
    };

    return (
      <div style={{ minHeight: "200px" }}>
        <Bar
          data={data}
          width={this.props.width ? this.props.width : 433}
          height={this.props.height ? this.props.height : 200}
          options={options}
        />
      </div>
    );
  }
}

export default CanvasChart2;
