import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
/// Scroll

/// Image
import profile from "../../../images/profile/pic1.jpg";
import { getAdminInfo } from "../../../services/AuthService";
import { adminEmail } from "../../../services/Common";
import swal from "sweetalert";

const Header = ({ onNote }) => {
  const [profilePath, setProfilePath] = useState(profile);
  const getAdmininfoData = async () => {
    const payload = {
      email: adminEmail,
    };
    const data = await getAdminInfo(payload);
    if (data.status === 200) {
      if (
        data.data.user &&
        data.data.user.profilePicture != "" &&
        data.data.user.profilePath != ""
      ) {
        if (typeof data.data.user.profilePath == "undefined") {
          setProfilePath(
            "https://t4.ftcdn.net/jpg/02/17/34/67/240_F_217346782_7XpCTt8bLNJqvVAaDZJwvZjm0epQmj6j.jpg"
          );
        } else {
          let profilePath = `${data.data.user.profilePath}`;
          setProfilePath(profilePath);
        }
      }
    } else {
      swal("Oops", "Some error to fetch error", "error");
    }
  };
  useEffect(() => {
    setTimeout(() => {
      getAdmininfoData();
    }, 500);
  }, []);
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
      ? filterName.filter((f) => f !== "ui")
      : filterName.includes("uc")
        ? filterName.filter((f) => f !== "uc")
        : filterName.includes("basic")
          ? filterName.filter((f) => f !== "basic")
          : filterName.includes("jquery")
            ? filterName.filter((f) => f !== "jquery")
            : filterName.includes("table")
              ? filterName.filter((f) => f !== "table")
              : filterName.includes("page")
                ? filterName.filter((f) => f !== "page")
                : filterName.includes("email")
                  ? filterName.filter((f) => f !== "email")
                  : filterName.includes("ecom")
                    ? filterName.filter((f) => f !== "ecom")
                    : filterName.includes("chart")
                      ? filterName.filter((f) => f !== "chart")
                      : filterName.includes("editor")
                        ? filterName.filter((f) => f !== "editor")
                        : filterName;
  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ") === "dashboard dark"
                    ? "Dashboard"
                    : finalName.join(" ")}
              </div>
              <div className="nav-item d-flex align-items-center"></div>
            </div>
            <ul className="navbar-nav header-right main-notification">
              <li className="nav-item header-profile">
                <Link
                  to={"#"}
                  className="nav-link"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  <img
                    src={profilePath}
                    style={{ objectFit: "cover" }}
                    width="20"
                    alt=""
                  />
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
