import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { loadingToggleAction,loginAction,
	loginConfirmedAction } from '../../store/actions/AuthActions';
import { login as loginApi, 
	saveTokenInLocalStorage, 
	runLogoutTimer } from '../../services/AuthService';

// image
import logo from "../../images/logo.png";
import loginbg from "../../images/people-graphics-bottom.png";

function Login (props) {
const baseBtnLoginText = 'Login'
const [email, setEmail] = useState('');
let errorsObj = { email: '', password: '' };
const [errors, setErrors] = useState(errorsObj);
const [password, setPassword] = useState('');
const [btnLoginText, setBtnLoginText] = useState(baseBtnLoginText)

const dispatch = useDispatch();

function onLogin() {
	setBtnLoginText('Authorizing...')
	loginApi(email, password).then((_resp) => {
		console.log(_resp)
		const data = {
			"kind": "identitytoolkit#VerifyPasswordResponse",
			"localId": "qmt6dRyipIad8UCc0QpMV2MENSy1",
			"email": email,
			"displayName": "",
			"idToken": _resp.data.token,
			"registered": true,
			"refreshToken": "AIwUaOlgTiq70YnpOaUebnnSfGX1dF-YPVulafYqBpbeVItuapCqkzdup8NDZpOhnWZEt9KuS-uIVCVO75Y6DSP0LLDLmsiXbEnopmzxHhfGZbCm0svzgnNk66LuZkOCHTcEPiahBwRZaVSiv3Y1H7zcEtdeVfVSxMHkHsBwIy2W0WLeo6zlIDmQI4qrH1XsMwBcGAJlskhIsesHJNgOFuxyemayGk2nCg",
			"expiresIn": "3600",
			"expireDate": "2022-06-10T17:13:06.858Z"
		}
		saveTokenInLocalStorage(data);
		runLogoutTimer(
			dispatch,
			data.expiresIn * 1000,
			history,
		);
		dispatch(loginConfirmedAction(data));
		history.push('/dashboard');       
	}).catch((_resp) => {
		console.error(_resp)
	}).finally(() => {
		setBtnLoginText(baseBtnLoginText)
	})
}

return (
	<div className="authincation d-flex flex-column flex-lg-row flex-column-fluid" style={{background:"#fedb59"}}>
		<div className="login-aside text-center  d-flex flex-column flex-row-auto">
			<div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
				<div className="text-center mb-4 pt-5">
					<img src={logo} alt="" />
				</div>
				<h3 className="mb-2"> Welcome, Admin!</h3>
				<p>Please sign in with an Admin account to continue</p>
			</div>
			<div className="aside-image position-relative" >
				<img src={loginbg} alt="" style={{position: 'absolute',bottom: '0px',width: '100%',left: '0px'}} />
			</div>
		</div>
		<div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
			<div className="d-flex justify-content-center h-100 align-items-center">
				<div className="authincation-content style-2">
					<div className="row no-gutters">
						<div className="col-xl-12 tab-content">
							<div id="sign-in" className="auth-form   form-validation">
								{props.errorMessage && (
									<div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2 text-danger'>
										{props.errorMessage}
									</div>
								)}
								{props.successMessage && (
									<div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
										{props.successMessage}
									</div>
								)}
								<form onSubmit={onLogin}  className="form-validate">
									<h3 className="text-center mb-4 text-black">Sign in your account</h3>
									<div className="form-group mb-3">
										<label className="mb-1"  htmlFor="val-email"><strong>Email</strong></label>
										<div>
											<input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" placeholder="Type Your Email Address"
											/>
										</div>
										{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
									</div>
									<div className="form-group mb-3">
										<label className="mb-1"><strong>Password</strong></label>
										<input value={password} onChange={(e) => setPassword(e.target.value)} type="password" className="form-control" placeholder="Type Your Password"/>
										{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
									</div>
									<div className="form-row d-flex justify-content-between mt-4 mb-2">
										<div className="form-group mb-3">
											<div className="custom-control custom-checkbox ml-1">
												<input type="checkbox" className="form-check-input" id="basic_checkbox_1" />
												<label className="form-check-label" htmlFor="basic_checkbox_1">Remember my preference</label>
											</div>
										</div>
									</div>
									<div className="text-center form-group mb-3">
										<button onClick={() => onLogin()} disabled={btnLoginText !== baseBtnLoginText} type="button" className="btn btn-primary btn-block">
											{btnLoginText}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>	
)};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);
