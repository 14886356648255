import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import swal from "sweetalert";
import Alert from "sweetalert2";
import { getCategories } from "../../../services/PersonalBuilderSystem";

export default function ListSelfAssessment(props) {
  //Modal box
  const CatIdConditionId = localStorage.getItem("catId")
    ? localStorage.getItem("catId")
    : "6278a8bf982c0746c38c26f2";
  const modeCondition = localStorage.getItem("mode")
    ? localStorage.getItem("mode")
    : 2;
  const [catId, setCatId] = useState(`${CatIdConditionId}`);

  const [contacts, setContacts] = useState();
  const [mode, setMode] = useState(modeCondition);

  const [modalData, setModalData] = useState([]);

  //Edit start
  const [editModal, setEditModal] = useState(false);
  // Edit function editable page loop
  const [categories, setcategories] = useState([]);

  // Edit function button click to edit
  const handleEditClick = (event, id) => {
    event.preventDefault();
    props.history.push("/edit-personal-Builder");
    localStorage.setItem("pid", id);
  };

  const handleModalData = (data) => {
    console.log(data);
  };

  async function getCategoryData() {
    const response = await getCategories();
    if (response.status == 200) {
      setcategories(response.data.category);
    }
  }
  useEffect(() => {
    getCategoryData();
  }, []);

  return (
    <>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="fs-20 font-w600  me-auto">Personal Builder List</h4>
        <div>
          <Link
            to={"/add-personal-Builder"}
            className="btn btn-primary me-3 btn-sm"
          >
            <i className="fas fa-plus me-2"></i>Add Personal Builder Result
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <div className="table-responsive">
            <table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Result</th>
                  <th className="action-buttons d-flex justify-content-end me-5">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {categories?.length >= 1 ? (
                  categories.map((category, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className="">{category.result}</td>
                      <td>
                        <div className="action-buttons d-flex justify-content-end">
                          <Link
                            to={"#"}
                            className="btn btn-secondary light mr-2"
                            onClick={(event) =>
                              handleEditClick(event, category._id)
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24px"
                              height="24px"
                              viewBox="0 0 24 24"
                              version="1.1"
                              className="svg-main-icon"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24"></rect>
                                <path
                                  d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                                  fill="#000000"
                                  fillRule="nonzero"
                                  transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                                ></path>
                                <rect
                                  fill="#000000"
                                  opacity="0.3"
                                  x="5"
                                  y="20"
                                  width="15"
                                  height="2"
                                  rx="1"
                                ></rect>
                              </g>
                            </svg>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colspan="4">
                      <h4 className="text-center mt-3">No Record Found</h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
