import React,{useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, Modal} from 'react-bootstrap';
import swal from "sweetalert";
import Alert from "sweetalert2";
import { getAssessmentList,getCategories,deleteAssessmentList } from "../../../services/SelfAssessmentServices";


export default function ListSelfAssessment(props) {
	
	//Modal box
	const CatIdConditionId = (localStorage.getItem("catId")) ? localStorage.getItem("catId") : "6278a8bf982c0746c38c26f2"
	const modeCondition = (localStorage.getItem("mode")) ? localStorage.getItem("mode") : 2
	const[catId,setCatId] = useState(`${CatIdConditionId}`);
	
	const [contacts, setContacts] = useState();
	const [mode, setMode] = useState(modeCondition);
	
	const [modalData, setModalData] = useState([]);
	
	// delete data  
	const handleDeleteClick = async (contactId) => {	
		Alert.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then(async(result) => {
			if (result.isConfirmed) {
				const response = await deleteAssessmentList(contactId);
				if (response.status == 200) {
					Alert.fire(
						'Deleted!',
						'Your data has been deleted.',
						'success'
					)
				getAssessmentData();
				props.history.push("/self-Assessment");
				} else {
					swal('Oops', "Some error...", "error");
				}
			}
		})
		
        
    }
	//Edit start 
	const [editModal, setEditModal] = useState(false);	
	// Edit function editable page loop
  const [categories, setcategories] = useState([]);
   
    // Edit function button click to edit
    const handleEditClick = ( event, contact) => {
        event.preventDefault();
		props.history.push("/edit-self-Assessment");
		localStorage.setItem('sid', contact._id);
    };

    
	const handleModalData = (data) => { 
		console.log(data)
		setModalData(data)
		setEditModal(true)
	}

	const getAssessmentData = async () => { 
		let payload = {
			category: catId,
			mode: mode,
		}
		const response = await getAssessmentList(payload);
		if (response.status == 200) {
			setContacts(response.data.result)
		  } 
		
	 
	}
	
	async function getCategoryData() {
		const response = await getCategories();
		if (response.status == 200) {
			setcategories(response.data.category)
		} 
	  }
	useEffect(() => {
		getCategoryData();
	}, []);
	useEffect(() => {
		getAssessmentData();
	}, [catId,mode]);
	
	return(
		<>
			<div className="d-flex align-items-center mb-4 flex-wrap">
				<h4 className="fs-20 font-w600  me-auto">Self Assessment List</h4>
				<div>
					<Link to={"/add-self-AssessMent"} className="btn btn-primary me-3 btn-sm">
						<i className="fas fa-plus me-2"></i>Add Self Assessment
					</Link>
					<Modal className="modal fade " centered  show={editModal} onHide={setEditModal} >
						<div  role="document">
							<div>
								{(modalData?.length >= 1) ?
									<table className="table display mb-4 dataTablesCard job-table table-responsive no-footer p-5" >
										<thead>
											<tr >
												<th className="ps-5">Options</th>
												<th>Type</th>
												<th>Percentage</th>
												</tr>
										</thead>
										<tbody>
											{
												modalData.map((val) => { 
													return (
														<tr>
														<td className="ps-5">{ val.options}</td>
														<td>{ val.result}</td>
														<td>{ val.percentage}%</td>
													</tr>)
												})
											}
											
										</tbody>
									</table>
								: ""}
								
								
							</div>
						</div>
					</Modal>
				</div>
			</div>
			
			<div className="row">
				<div className="col-xl-12">
					<div className="form-group mb-3 position-relative">
					<i class="fa fa-angle-down position-absolute start-3" aria-hidden="true" style={{fontSize:'25px',right:'25px',top:'18px'}}></i>
						
                  <select
							defaultValue={"option"}
							onChange={(e) => {
								localStorage.removeItem("catId");
								setCatId(e.target.value)
							}}
							className="form-control form-control-lg"
							value={catId}
                  >
                    {(categories) ?
                      categories.map((val, idx) => {
                        return (
                          <option value={val._id} id={`${val.category}` } key={idx}>
                            {val.category}
                          </option>
                        );
                      })
                    :""}
                  </select>
                </div>
				</div>
			</div>
			{/* filter start */}
			<div className="text-right mb-2" style={{textAlign: 'end'}}>
					<Dropdown className="btn-group mb-1 ">
                    <Dropdown.Toggle
                      type="button"
                      className="btn btn-primary me-3 btn-sm dropdown-toggle px-4"
                      data-toggle="dropdown"
                    >
					{(mode == 2)?'Job Poster':'Job Seeker'}<span className="caret m-l-5"></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu">
                      <Dropdown.Item
                        className="dropdown-item"
						to="#"
							onClick={() => {
								setMode(2);
								localStorage.removeItem("mode");

							}}
                      >
                       Job Poster
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item"
						to="#"
							onClick={() => {
								setMode(1);
								localStorage.removeItem("mode");
							}}
							
                      >
                       Job Seeker
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  	</Dropdown>
			</div>
			{/* filter end */}
			<div className="row">
			<div className="col-xl-12">
				<div className="table-responsive">
					<table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer" >
						<thead>
							<tr>
								<th>No</th>
								<th>Question</th>
								<th className="text-center">{`Options & Result`}</th>
								<th  className="action-buttons d-flex justify-content-end me-5">Actions</th>
							</tr>
						</thead>
								<tbody>
									{(contacts?.length >= 1) ?
										contacts.map((contact,index)=>(
										<tr key={index}>
											<td>{ index+1}</td>
											<td className="">{contact.question}</td>
											<td className="text-center">
												<button className="btn btn-secondary" onClick={()=>{handleModalData(contact.answers)}}>View</button>
											</td>
											<td>
												<div className="action-buttons d-flex justify-content-end">
													
													<Link to={"#"} className="btn btn-secondary light mr-2"
														onClick={(event) => handleEditClick(event, contact)}
													>
														<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
															<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																<rect x="0" y="0" width="24" height="24"></rect>
																<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
																<rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
															</g>
														</svg>
													</Link>
													<Link to={"#"} className="btn btn-danger light"
														onClick={()=>handleDeleteClick(contact._id)}
													>
														<svg xmlns="http://www.w3.org/2000/svg"  width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
															<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
																<rect x="0" y="0" width="24" height="24"></rect>
																<path d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z" fill="#000000" fillRule="nonzero"></path>
																<path d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"></path>
															</g>
														</svg>
													</Link>
												</div>
											</td>
										</tr>
										))
									:<tr><td colspan="4"><h4 className="text-center mt-3">No Record Found</h4></td></tr>}
							
						</tbody>	
					</table>
				</div>		
			</div>	
				
				</div>
				 
				
		</>
	)	
}