import { postData } from "./requests";

export const getJobById = (_job_id) => {
    const url = 'jobposter/fetchjobposter';
    const requestData = {
        'jobId': _job_id,
        'userId': ''
    };
    return postData(url, requestData)
}

export const updateJob = (_job) => {
    const url = 'jobposter/updateJobData';
    const requestData = _job;
    return postData(url, requestData)
}

export const setPercentageAndPointsForAllJobs = (_job_id, _job_user_id) => {
    const url = 'jobposter/setPercentageAndPointsForAllJobs'
    const requestData = {
        'id': _job_id, 
        'userId': _job_user_id
    };
    return postData(url, requestData)
}