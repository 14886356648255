import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Dropdown } from 'react-bootstrap';
import swal from "sweetalert";
import { getRolesPayload, addRole, deleteRole, updateRole, getRoleQuestionsPayload, updateRoleQuestion, ruleApprove, ruleDisapprove } from "../../../services/RoleServices"
import { answersType, answersStatusBG, answersStatus } from "../../../services/Common"
import Alert from "sweetalert2";
import HardSkillTable from '../table/hard-skills-table';

const HardSkill = () => {

//Modal box
const page = "hardskill";
const questionTitle = "Hard Skill"

const [addCard, setAddCard] = useState(false);
const [filterUser, setFilterUser] = useState("admin");
const [filterUserName, setFilterUserName] = useState("Admin User");
const [filter, setFilter] = useState(3);
const [editModal, setEditModal] = useState(false);
const [editContactId, setEditContactId] = useState(null);
const [contacts, setContacts] = useState([]);
const [editTitleError, setEditTitleError] = useState(false);
const [addTitleError, setAddTitleError] = useState(false);
const [filterName, setFilterName] = useState("Filter By");
const [editQuestion, setEditQuestion] = useState({
	jobPoster: false,
	jobSeeker: false
});
const [roleQuestion, setRoleQuestion] = useState();
const [jobPosterQuestion, setJobPosterQuestion] = useState();
const [jobSeekerQuestion, setJobSeekerQuestion] = useState();
const [questionError, setQuestionError] = useState({
	jobPoster: false,
	jobSeeker: false
});
//Add data 
const [addFormData, setAddFormData] = useState({
	option: '',
	category: page,
	usertype: '1'
});
// edit  data  
const [editFormData, setEditFormData] = useState({
	option: '',
	usertype: '1'
})

const payload = {
	category: page,
	search: filter,
	filterby: filterUser
}

useEffect(() => {
	// getRoleQuestion();
	getRole();
}, []);

// delete data  
const handleDeleteClick = (contactId) => {

	Alert.fire({
		title: 'Are you sure?',
		text: "You won't be able to revert this!",
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#3085d6',
		cancelButtonColor: '#d33',
		confirmButtonText: 'Yes, delete it!'
	}).then(async (result) => {
		if (result.isConfirmed) {
			let response = await deleteRole(contactId);
			if (response.status == 200) {
				getRole();
				Alert.fire(
					'Deleted!',
					'Your data has been deleted.',
					'success'
				)
			} else {
				swal('Oops', "Some error...", "error");
			}
		}
	})


}
// Add contact function
const handleAddFormChange = (event) => {
	event.preventDefault();
	const fieldName = event.target.getAttribute('name');
	const fieldValue = event.target.value;
	const newFormData = { ...addFormData };
	newFormData[fieldName] = fieldValue;
	setAddFormData(newFormData);
};
// Edit function button click to edit
const handleEditClick = (event, contact) => {
	event.preventDefault();
	setEditContactId(contact._id);
	const formValues = {
		option: contact.option,
		usertype: contact.usertype
	}
	setEditFormData(formValues);
	setEditModal(true);
};
//update data function
const handleEditFormChange = (event) => {
	const fieldName = event.target.getAttribute('name');
	const fieldValue = event.target.value;
	const newFormData = { ...editFormData };
	newFormData[fieldName] = fieldValue;
	setEditFormData(newFormData);
};
// edit form data submit
const handleEditFormSubmit = async () => {
	if (editFormData.option.trim() === "") {
		setEditTitleError(true);
	} else {
		let response = await updateRole(editFormData, editContactId);
		if (response.status == 200) {
			getRole();
			swal('Good job!', response.data.message, "success");
			setEditContactId(null);
			setEditModal(false);
		} else {
			swal('Oops', "Some error...", "error");
		}
	}
}
const handleNewRole = async () => {
	let response = await addRole(addFormData);
	if (response.status == 200) {
		getRole();
		swal('Good job!', response.data.message, "success");
		setAddCard(false)
		setAddFormData({
			option: '',
			category: page,
			usertype: '1'
		})

	} else {
		swal('Oops', "Some error...", "error");
	}
}
const handleAddRole = () => {
	if (addFormData.option.trim() === "") {
		setAddTitleError(true);
	} else {
		setAddTitleError(false);
		handleNewRole()
	}

}
const handleApprove = async (id) => {
	const response = await ruleApprove(id);
	if (response.status == 200) {
		swal('Good job!', response.data.message, "success");
		getRole()
	} else {
		swal('Oops', "Some error...", "error");
	}
}
const handleDenied = async (id) => {
	const response = await ruleDisapprove(id);
	if (response.status == 200) {
		swal('Good job!', response.data.message, "success");
		getRole()
	} else {
		swal('Oops', "Some error...", "error");
	}
}
const handlePosterQuestion = async () => {
	if (jobPosterQuestion.question.trim() === "") {
		setQuestionError({ jobPoster: true, jobSeeker: questionError.jobSeeker })
	} else {
		setQuestionError({ jobPoster: false, jobSeeker: questionError.jobSeeker })
		const response = await updateRoleQuestion(jobPosterQuestion, roleQuestion['0']._id);
		if (response.status == 200) {
			swal('Good job!', response.data.message, "success");
			getRoleQuestion()
			setEditQuestion({ jobPoster: false, jobSeeker: editQuestion.jobSeeker })
		} else {
			swal('Oops', "Some error...", "error");
		}
	}
}
const handleSeekerQuestion = async () => {
	if (jobSeekerQuestion.question.trim() === "") {
		setQuestionError({ jobSeeker: true, jobPoster: questionError.jobPoster })
	} else {
		setQuestionError({ jobSeeker: false, jobPoster: questionError.jobPoster })
		const response = await updateRoleQuestion(jobSeekerQuestion, roleQuestion['1']._id);
		if (response.status == 200) {
			swal('Good job!', response.data.message, "success");
			getRoleQuestion()
			setEditQuestion({ jobSeeker: false, jobPoster: editQuestion.jobPoster })
		} else {
			swal('Oops', "Some error...", "error");
		}
	}
}
// API creator
async function getRoleWithFilter(id) {
	let searchPayload = {
		category: page,
		search: id,
		filterby: `${filterUser}`
	}
	const response = await getRolesPayload(searchPayload);
	if (response.status == 200) {
		setContacts(response.data.result)
	}
}
async function getRoleWithSearch(name) {
	let searchPayload = {
		category: page,
		search: filter,
		filterby: `${name}`
	}
	const response = await getRolesPayload(searchPayload);
	if (response.status == 200) {
		setContacts(response.data.result)
	}
}
function getRole() {
	// const response = await getRolesPayload(payload);
	// if (response.status == 200) {
	// 	setContacts(response.data.result)
	// }

	getRolesPayload(payload).then((_resp) => {
		let data = _resp.data.result
		data.forEach(ele => {
			ele['statusReal'] = answersStatus[ele.status]
			ele['userTypeReal'] = answersType[ele.usertype]
		});
		setContacts(data);
	}).catch((_resp) => {

	}).finally(() => {

	})
}
async function getRoleQuestion() {
	const response = await getRoleQuestionsPayload(payload);
	if (response.status == 200) {
		setRoleQuestion(response.data.result)
		setJobSeekerQuestion({ question: response.data.result['1'].question })
		setJobPosterQuestion({ question: response.data.result['0'].question })
	}
}

function onHardSkillTableResp(_cmd, _value){

}

return (
	<>
		<div className="d-flex align-items-center mb-4 flex-wrap">
			{/* <h4 className="fs-20 font-w600  me-auto">{questionTitle}</h4> */}
			<div>
				<Modal className="modal fade" show={addCard} onHide={setAddCard} >
					<div role="document">
						<div className="">

							<div className="modal-header">
								<h4 className="modal-title fs-20">Add {questionTitle}</h4>
								<button type="button" className="btn-close" onClick={() => setAddCard(false)} data-dismiss="modal"><span></span></button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className="form-group mb-3">
											<label className="text-black font-w500 text-capitalize">Title</label>
											<div className="contact-name">
												<input type="text" className="form-control" autocomplete="off"
													name="option" required="required"
													onChange={handleAddFormChange}
													placeholder="Title"
												/>
												<span className="validation-text text-danger">{(addTitleError) ? "Please Enter Title" : null}</span>
											</div>
										</div>

										<div className="form-group mb-3">
											<label className="text-black font-w500">Type</label>
											<div className="contact-name position-relative">
												<i class="fa fa-angle-down position-absolute start-3" aria-hidden="true" style={{ fontSize: '25px', right: '25px', top: '18px' }}></i>

												<select
													defaultValue={"option"}
													name="usertype"
													onChange={handleAddFormChange}
													className="form-control form-control-lg"
												>
													<option value="1">Job Seeker</option>
													<option value="2">Job Poster</option>
													<option value="3">Both</option>

												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="submit" onClick={handleAddRole} className="btn btn-success" >Add</button>
								<button type="button" onClick={() => setAddCard(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>
							</div>

						</div>
					</div>
				</Modal>
				<Modal className="modal fade" show={editModal} onHide={setEditModal} >
					<div role="document">
						<div>
							<div className="modal-header">
								<h4 className="modal-title fs-20">Edit {questionTitle}</h4>
								<button type="button" className="btn-close" onClick={() => setEditModal(false)} data-dismiss="modal"><span></span></button>
							</div>
							<div className="modal-body">
								<i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
								<div className="add-contact-box">
									<div className="add-contact-content">
										<div className="form-group mb-3">
											<label className="text-black font-w500 text-capitalize">Title</label>
											<div className="contact-name">
												<input type="text" className="form-control" autocomplete="off"
													name="option" required="required"
													value={editFormData.option}
													onChange={handleEditFormChange}
													placeholder="Title"
												/>
												<span className="validation-text text-danger">{(editTitleError) ? "Please Enter Title" : null}</span>
											</div>
										</div>

										<div className="form-group mb-3">
											<label className="text-black font-w500">Type</label>
											<div className="contact-name position-relative">
												<i class="fa fa-angle-down position-absolute start-3" aria-hidden="true" style={{ fontSize: '25px', right: '25px', top: '18px' }}></i>

												<select
													defaultValue={"option"}
													name="usertype"
													value={editFormData.usertype}
													onChange={handleEditFormChange}
													className="form-control form-control-lg"
												>
													<option value="1">Job Seeker</option>
													<option value="2">Job Poster</option>
													<option value="3">Both</option>

												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="submit" className="btn btn-primary" onClick={handleEditFormSubmit}>Save</button>
								<button type="button" onClick={() => setEditModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>
							</div>

						</div>
					</div>
				</Modal>
			</div>
		</div>
		{(roleQuestion) ?
			<div className="row card mx-0">
				<div className="col-xl-12">
					<div className="mb-1 row">
						<div className="col-sm-3 pt-2">
							<label
								className="col-sm-12 col-form-label mt-3"
								style={{ fontWeight: "bold" }}
							>
								<h4>{questionTitle} Question (Job poster):</h4>
							</label>
						</div>

						<div className="col-sm-6 pt-2">
							{(editQuestion.jobPoster) ?
								<>
									<input
										type="text"
										className="form-control"
										value={jobPosterQuestion.question}
										onChange={(e) => { setJobPosterQuestion({ question: e.target.value }) }}
										placeholder="Enter your Question Here..."
									/>
									<span className="validation-text text-danger">{(questionError.jobPoster) ? "Please Enter Question" : null}</span>

								</>


								: <h4 className="pt-3">{roleQuestion['0'].question}</h4>}

						</div>
						<div className="col-sm-3 pt-2">
							{(editQuestion.jobPoster) ?
								<>.
									<button type="submit" onClick={handlePosterQuestion} className="btn btn-secondary" >Save</button>
									<button onClick={() => { setEditQuestion({ jobPoster: false, jobSeeker: editQuestion.jobSeeker }) }} className="btn btn-danger ms-3" >Cancel</button> </>
								:
								<Link to={"#"} className="btn btn-secondary light mr-2"
									onClick={() => { setEditQuestion({ jobPoster: true, jobSeeker: editQuestion.jobSeeker }) }}>
									<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
										<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<rect x="0" y="0" width="24" height="24"></rect>
											<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
											<rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
										</g>
									</svg>
								</Link>
							}
						</div>
					</div>
				</div>
				<div className="col-xl-12 mx-0">
					<div className="mb-1 row">
						<div className="col-sm-3 pt-2">
							<label
								className="col-sm-12 col-form-label mt-3"
								style={{ fontWeight: "bold" }}
							>
								<h4>{questionTitle} Question (Job seeker):</h4>
							</label>
						</div>

						<div className="col-sm-6 pt-2">
							{(editQuestion.jobSeeker) ?
								<>
									<input
										type="text"
										className="form-control"
										value={jobSeekerQuestion.question}
										onChange={(e) => { setJobSeekerQuestion({ question: e.target.value }) }}
										placeholder="Enter your Question Here..."
									/>
									<span className="validation-text text-danger">{(questionError.jobSeeker) ? "Please Enter Question" : null}</span>

								</>


								: <h4 className="pt-3">{roleQuestion['1'].question}</h4>}

						</div>
						<div className="col-sm-3 pt-2">
							{(editQuestion.jobSeeker) ?
								<>
									<button type="submit" onClick={handleSeekerQuestion} className="btn btn-secondary" >Save</button>
									<button onClick={() => { setEditQuestion({ jobPoster: editQuestion.jobPoster, jobSeeker: false }) }} className="btn btn-danger ms-3" >Cancel</button>
								</>
								:
								<Link to={"#"} className="btn btn-secondary light mr-2"
									onClick={() => { setEditQuestion({ jobPoster: editQuestion.jobPoster, jobSeeker: true }) }}>
									<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
										<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
											<rect x="0" y="0" width="24" height="24"></rect>
											<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
											<rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
										</g>
									</svg>
								</Link>
							}
						</div>
					</div>
				</div>
			</div>

			: null}

		<div className="row">
			<div className="col-xl-12">
				<div className="text-right mb-2" style={{ textAlign: 'end' }}>
					<Link to={"#"} className="btn btn-primary me-3 btn-sm px-4 mb-1" onClick={() => setAddCard(true)}>
						<i className="fas fa-plus me-2"></i>Add New
					</Link>
					<Dropdown className="btn-group mb-1 ">
						<Dropdown.Toggle
							type="button"
							className="btn btn-primary me-3 btn-sm dropdown-toggle px-5"
							data-toggle="dropdown"
						>
							{filterName}<span className="caret m-l-5"></span>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu">
							<Dropdown.Item
								className="dropdown-item"
								to="#"
								onClick={() => {
									setFilterName("Job Seeker");
									setFilter(1)
									getRoleWithFilter(1)
								}}
							>
								Job Seeker
							</Dropdown.Item>
							<Dropdown.Item
								className="dropdown-item"
								to="#"
								onClick={() => {
									setFilterName("Job Poster");
									setFilter(2)
									getRoleWithFilter(2)
								}}
							>
								Job Poster
							</Dropdown.Item>
							<Dropdown.Item
								className="dropdown-item"
								to="#"
								onClick={() => {
									setFilterName("Both");
									setFilter(3)
									getRoleWithFilter(3)
								}}

							>
								Both
							</Dropdown.Item>

						</Dropdown.Menu>
					</Dropdown>

					<Dropdown className="btn-group mb-1 ">
						<Dropdown.Toggle
							type="button"
							className="btn btn-primary me-3 btn-sm dropdown-toggle px-4"
							data-toggle="dropdown"
						>
							{filterUserName}<span className="caret m-l-5"></span>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu">
							<Dropdown.Item
								className="dropdown-item"
								to="#"
								onClick={() => {
									setFilterUser("admin");
									setFilterUserName("Admin User");
									getRoleWithSearch("admin");
								}}
							>
								Admin User
							</Dropdown.Item>
							<Dropdown.Item
								className="dropdown-item"
								to="#"
								onClick={() => {
									setFilterUser("front");
									setFilterUserName("Front-End User");
									getRoleWithSearch("front");
								}}
							>
								Front-End User
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<HardSkillTable _data={contacts} _onResp={onHardSkillTableResp}/>
				{/* <div className="table-responsive">
					<table className="table display mb-4 dataTablesCard job-table table-responsive-xl card-table dataTable no-footer" >
						<thead>
							<tr>
								<th>No</th>
								<th>{questionTitle}</th>
								<th className="text-center">Status</th>
								<th className="text-center">{(filterUser == 'front') ? 'Request by' : 'User Type'}</th>
								{(filterUser == 'front') ? <th className="text-center">Name</th> : ""}
								<th className="action-buttons d-flex justify-content-end me-5">Actions</th>
							</tr>
						</thead>
						<tbody>
							{(contacts?.length >= 1) ?
								contacts.map((contact, index) => (
									<tr key={index}>
										<td>#{index + 1}</td>
										<td>{contact.option}</td>
										<td className="text-center"><span className={`badge ${(answersStatusBG[contact.status])} badge-lg light`}>{(answersStatus[contact.status])}</span></td>
										<td className="text-center">{answersType[contact.usertype]}</td>
										{(filterUser == 'front') ? <td className="text-center">{contact.name}</td> : ""}
										<td>
											<div className="action-buttons d-flex justify-content-end">
												{handleApproveDenied(contact)}
												<Link to={"#"} className="btn btn-secondary light mr-2"
													onClick={(event) => handleEditClick(event, contact)}
												>
													<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
														<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
															<rect x="0" y="0" width="24" height="24"></rect>
															<path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fillRule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "></path>
															<rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"></rect>
														</g>
													</svg>
												</Link>
											</div>
										</td>
									</tr>
								))
								: <tr><td colspan={`${(filterUser == 'front') ? '6' : '5'}`}><h4 className="text-center mt-3">No Record Found</h4></td></tr>}
						</tbody>
					</table>
				</div> */}
			</div>
		</div>
	</>
)}
export default HardSkill;